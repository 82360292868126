import React, { Component } from 'react';
import Logo from '../images/ttl-logo.png';
import Navbar from 'react-bootstrap/Navbar'
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
class Header extends Component {
  handleSearchSubmit = (event) => {
    // const formdata = new FormData(event.target)
    const filter = {};
    localStorage.setItem("lastSearch", "search");
    localStorage.setItem("tic_tac_selected_search", this.state.selected_search);
    filter["search"] = this.state.selected_search;
    getVideosContent(filter)
      .then((response) => {
        this.setState(() => ({ videos: [] }));
        this.setState(() => ({ videos: response.data.results }));
        this.setState(() => ({ videos_count: response.data.count }));
        // console.log(response);
      })
      .catch((error) => {
        console.log("Network Error");
        this.setState(() => ({ videos: [] }));
        // return <Redirect to='/404'  />
        // this.setState({ isValid: false });
      });
    getPracticeQuesContent(filter)
      .then((response) => {
        this.setState(() => ({ practiceQues: [] }));
        this.setState(() => ({ practiceQues: response.data.results }));
        this.setState(() => ({ practiceQues_count: response.data.count }));
        // console.log(response);
      })
      .catch((error) => {
        console.log("Network Error");
        this.setState(() => ({ practiceQues: [] }));
        // return <Redirect to='/404'  />
        // this.setState({ isValid: false });
      });

    // fetch playlist details
    getPlaylistContent(filter)
      .then((response) => {
        this.setState(() => ({ playlists: [] }));
        this.setState(() => ({ playlists: response.data.results }));
        this.setState(() => ({ playlists_count: response.data.count }));
        // console.log(response);
      })
      .catch((error) => {
        console.log("Network Error");
        this.setState(() => ({ playlists: [] }));
        this.setState(() => ({ practiceQues_count: 0 }));
        // return <Redirect to='/404'  />
        // this.setState({ isValid: false });
      });
    event.preventDefault();
  };
  render() {
      const mystyle ={
          // color: '#000',
          // backgroundColor: "DodgerBlue",
      }
    return (
        <Navbar sticky="top" className="p-0 portal-header">
          <div className="header-section">
              <div className="container">
                <div className="col-md-4"></div>
                <div className="col-md-4"> 
                <a className='navbar-brand text-center d-flex width-100' href={ window.location.origin.toString() } style={mystyle} >
                    <img src={Logo} alt=""/>
                </a>
                </div>
                <div className="col-md-4">
                
                </div>
               
              </div>
          </div>
        </Navbar>
    );
  }
}

export default Header;
