import http from "./httpServices";

const videoApiEndPoint = http.API_URL + "api/video";
const practiceQuesApiEndPoint = http.API_URL + "api/practice";
const playlistApiEndPoint = http.API_URL + "api/playlist";
// const playlistApiEndPoint = "http://157.245.96.72:8081/api/playlist";


function videoUrl() {
  return `${videoApiEndPoint}/`;
}

function practiceQuesUrl() {
    return `${practiceQuesApiEndPoint}/`;
}

function playListUrl() {
    return `${playlistApiEndPoint}/`;
}

export function getVideosContent(filter='') {
    const videos = http.get(videoUrl(), {
        params:filter
    });
    return videos;
}

export function getPracticeQuesContent(filter='') {
    const practiceQues = http.get(practiceQuesUrl(), {
        params:filter
    });
    return practiceQues;
}

export function getPlaylistContent(filter='') {
    console.log("from the api ------------>", filter)
    const playlist = http.get(playListUrl(), {
        params:filter
    });
    return playlist;
}