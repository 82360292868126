import React, { Component } from 'react';
import SchoolLogo from '../images/school-logo.png';



class SideBar extends Component {
    render() {
        return (
            <div className="sidebar-main">
                <div className="school-logo-box">
                    <img src={SchoolLogo} alt="" />
                    <p>Maharishi Vidyamandir</p>
                </div>
                <div className="school-bottom-content">
                    <p>Maharishi Vidyamandir trust has been established in 1885 by Rajanna Lingu Polas, Balkrishna Sayanna Motadu, B. Dastur, Dorabji Booty, Gangaram Bhau Mhaske and Mahatma Jyotiba Phule along with other socially committed people, to create social awareness and to spread the light of education for socially and financially backward sections of the society. The society takes pride in the fact that noted social reformer and educationalist Acharya Atre worked as Head Master for Camp Education Society for about two decades.</p>
                </div>
            </div>
        );
    }
}


export default SideBar;