import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class NotFound404 extends Component {
    state = {
        school: [],
    };
    
    render() {
        return (
            <div className="home-page-main">
                <div className="container">
                    <div className="row">
                        <h1>Page Not Found</h1>
                    </div>
                </div>
            </div>
        );
    }
}


export default NotFound404;