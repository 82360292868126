import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
// import CopyIcon from '../images/color-share-ic.png';
// import HomeIcon from '../images/home-ic.png';
import NextStepIcon from '../images/next-arrow-white.png';
import PrevStepIcon from '../images/previous-arrow-white.png';
import Form from 'react-bootstrap/Form';
import QuestionBottomImg from '../images/image-1.jpg';
// import { QuestionForm } from './QuestionForm';


export class QuestionForm extends Component {
    state = {
        QuestionTitle: '',
        CurrentQuestion: '',
        // AnswerA: '',
        // AnswerB: '',
        // AnswerC: '',
        // AnswerD: '',
        // CurrentQuestionImg: '',
        // TotalQuestionCount: '',
        // CurrentQuestionCount: '',
        questionAnswerDetails: [],
    }

    async componentDidMount() {

        const questionAnswerDetails = [
            {
                CurrentQuestion: 'Q.1 Identify the position of the object in the image below',
                AnswerA: 'Inside the table',
                AnswerB: '(B) Outside the table',
                AnswerC: '(C) On the table',
                AnswerD: '(D) Under the table',
                CurrentQuestionImg: '',
                TotalQuestionCount: '50',
                CurrentQuestionCount: '1',
            },
        ]

        const questionTital = 'लाम्बा छोटा';
        const currentQuestion = '';
        const answerA = '';
        const answerB = '';
        const answerC = '';
        const answerD = '';
        const currentQuestionImg = '';
        const totalQuestionCount = '';
        const currentQuestionCount = '';

        this.setState({
            QuestionTitle: questionTital,
            questionAnswerDetails: questionAnswerDetails,
        })
    }

    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    };
    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };
    render() {
        return (
            <div className="question-form">
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="question-heading">
                            <h1><b> {this.state.QuestionTitle} </b> <span>(Practice Quizzes)</span></h1>
                        </div>
                    </div>

                    <div className="col-md-12 col-sm-12">
                        <div className="start-question-content">
                            {this.state.questionAnswerDetails.map(item => (
                                <div className="start-question-box">
                                    <div className="question-Img">
                                        <h2> {item.CurrentQuestion}</h2>
                                        <img src={QuestionBottomImg} alt="" />
                                    </div>
                                    <div className="question-radio-group">
                                        <Form>
                                            <div className="row">
                                                <div className="col-md-6 col-sm-6 col-xs-12 radio-item">
                                                    <Form.Check
                                                        type="radio"
                                                        // label=" (A) '{item.AnswerA}' "
                                                        label={item.AnswerA}
                                                        name="formHorizontalRadios"
                                                        id="formHorizontalRadios1"
                                                    />
                                                </div>
                                                <div className="col-md-6 col-sm-6 col-xs-12">
                                                    <Form.Check
                                                        type="radio"
                                                        label={item.AnswerB}
                                                        name="formHorizontalRadios"
                                                        id="formHorizontalRadios2"
                                                    />
                                                </div>
                                                <div className="col-md-6 col-sm-6 col-xs-12">
                                                    <Form.Check
                                                        type="radio"
                                                        label="(C) On the table"
                                                        name="formHorizontalRadios"
                                                        id="formHorizontalRadios3"
                                                    />
                                                </div>
                                                <div className="col-md-6 col-sm-6 col-xs-12">
                                                    <Form.Check
                                                        type="radio"
                                                        label="(D) Under the table"
                                                        name="formHorizontalRadios"
                                                        id="formHorizontalRadios4"
                                                    />
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                    <div className="pagination-main">
                                        <p>(<span>{item.CurrentQuestionCount}</span>/<span>{item.TotalQuestionCount}</span>)</p>
                                    </div> 
                                </div>
                            ))}

                            <div className="d-flex justify-content-between">
                                <button className="btn btn-primary prev" onClick={this.back}> <img src={PrevStepIcon} alt="Previous" /> Previous</button>
                                <button className="btn btn-primary" onClick={this.back}>submit</button>
                                <button className="btn btn-primary next" onClick={this.continue}>  next <img src={NextStepIcon} alt="Next" /></button>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        )
    }
}

export default QuestionForm;