import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CopyIcon from '../images/color-share-ic.png';
import HomeIcon from '../images/home-ic.png';
import { QuestionEndForm } from './QuestionEndForm';
import { QuestionForm } from './QuestionForm';

export class QuestionStartForm extends Component {
    state = {
        TotalQuestion: '',
        QuestionTitle: '',
    }

    async componentDidMount() {
        const totalQuestion = '30';
        const questionTitle = 'लाम्बा छोटाs';
        this.setState({ TotalQuestion: totalQuestion, QuestionTitle: questionTitle, })
    }

    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    };
    render() {
        return (
            <div className="start-question">
                <div className="question-head">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="question-heading">
                                <h1><b> {this.state.QuestionTitle} </b> <span>(Practice Quizzes)</span></h1>
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <div className="start-question-content">
                                <div className="start-question-content-text">
                                    <h2>Total Questions <span>{this.state.TotalQuestion}</span></h2>
                                    <h3><span>Type:</span> Multiple type questions</h3>
                                    <h4>Click on Start to begin</h4>
                                </div>
                                <div className="text-center">
                                    <button className="btn btn-primary" onClick={this.continue}>Start</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default QuestionStartForm;