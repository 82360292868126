import http from "./httpServices";

const apiAnalyticsEndPoint = http.API_URL + "api/practice-analytic";
const shareAnalyticsEndPoint = http.API_URL + "api/share-link-analytic";

function practiceAnalyticsUrl() {
  return `${apiAnalyticsEndPoint}/`;
}

export function postPracticeAnalytics(data) {
  const response = http.post(practiceAnalyticsUrl(), {
    question_setId: data?.question_setId,
    qNo: data?.qNo,
    selected_option: data?.selected_option,
    correct_response: data?.correct_response,
    school_name: data?.school_name,
  });
  return response;
}

function linkAnalyticsUrl() {
  return `${shareAnalyticsEndPoint}/`;
}

export function postShareAnalytics(data) {
  const response = http.post(linkAnalyticsUrl(), {
    url: data?.url,
    school_name: data?.school_name,
    slug: data?.slug,
    content_type: data?.content_type,
    share_type: data?.type
  });
  return response;
}