import React, { Component } from 'react';
import { findDOMNode } from 'react-dom'
import { Link, Redirect } from 'react-router-dom';
import { getSchool } from "./services/schoolService";
import { getVideo } from "./services/videoService";
import { postShareAnalytics, postPracticeAnalytics } from "./services/analyticService";
import ReactPlayer from "react-player";
import SideBar from './component/sidebar';
import QuestionSetup from './component/QuestionSetup';
import SchoolLogo from './images/school-logo.png';
import NextStepIcon from './images/next-arrow-white.png';
import PrevStepIcon from './images/previous-arrow-white.png';
import CopyIcon from './images/color-share-ic.png';
// import HomeIcon from './images/home-ic.png';
import BackIcon from './images/back-icon.png';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PopupIcon1 from './images/whatup-ic1.svg';
import PopupIcon2 from './images/facebook.svg';
import PopupIcon4 from './images/twitter (1).svg';
import ReactGA from 'react-ga';
import Form from 'react-bootstrap/Form';
import toast, { toastConfig } from 'react-simple-toasts'
import screenfull from 'screenfull';
import Sidebar from "react-sidebar";
import ToggleIcon from './images/toggle.png';
import { Helmet } from 'react-helmet';
import ReactDOMServer from 'react-dom/server';

class Video extends Component {

    constructor(props) {
        super(props);
        this.state = {
            school: [],
            video: [],
            isValid: true,
            isPlaylist: false,
            copied: false,
            isOpen: false,
            isShareOpen: false,
            copiedLink: '',
            sidebarOpen: false
        };
        this.handleCopied = this.handleCopied.bind(this);
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);

        toastConfig({
            time: 3000,
            className: 'my-toast-message',
            position: 'left'
        });
    }
    onSetSidebarOpen(open) {
        this.setState({ sidebarOpen: open });
    }
    async componentDidMount() {
        const disablePinchZoom = (e) => {
            if (e.touches.length > 1) {
                e.preventDefault()
            }
        }
        document.addEventListener("touchmove", disablePinchZoom, { passive: false })

        ReactGA.set({ 'dimension1': this.props.match.params.school });
        ReactGA.set({ 'dimension2': 'Video' });
        ReactGA.event({
            'category': 'Practice Q/A',
            'action': 'Practice Q/A Visited'
        });
        ReactGA.pageview(window.location.pathname + window.location.search);

        await getSchool(this.props.match.params.school).then(response => {
            this.setState(() => ({ school: response.data }));
            // console.log(response);
        })
            .catch(error => {
                console.log("Page Not Found");
                this.setState({ isValid: false });
            });

        await getVideo(this.props.match.params.content_id).then(response => {
            this.setState(() => ({ video: response.data }));
            // console.log(response);
        })
            .catch(error => {
                console.log("Video Not Found");
                this.setState({ isValid: false });
            });

        // this.setState({ SchoolLogo: schoolLogo, SchoolName: schoolName, SchoolDescription: schoolDescription })
    };

    handleCopied(type) {
        // send to Google Analytics
        console.log("type ---->", type);
        ReactGA.set({ 'dimension4': this.state.copiedLink });
        ReactGA.event({
            'category': 'Link Copied',
            'action': 'Content Link Copied',
            'label': 'Content Link Copied',
            'value': this.state.copiedLink
        });
        
      
        const urls = window.location.href.split( 'v/' )[1];
        let data = {
            url: this.state.copiedLink,
            slug:urls ,
            content_type:'pl',
            school_name: this.props.match.params.school,
            type: type.toLowerCase()
        };
        // console.log(this.state);

        postShareAnalytics(data).then(response => {
            // console.log(response);
        })
            .catch(error => {
                console.log("Network Error");
                // return <Redirect to='/404'  />
                // this.setState({ isValid: false });
            });
        console.log(this.state);
    };

    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false });

    openShareModal = () => this.setState({ isShareOpen: true });
    closeShareModal = () => this.setState({ isShareOpen: false });

    handleClickFullscreen = () => {
        screenfull.request(findDOMNode(this.player))

    }
    ref = player => {
        this.player = player
    }

    onEnded = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        }
    }

    render() {
        //document.title = 'TicTacLearn|Video|' + this.state.video.video_title;
        if (!this.state.isValid)
            return <Redirect to='..' />
        return (
            <div className="home-page-main video-page-main">
                <Helmet>
                    <title>{'TicTacLearn|Video|' + this.state.video.video_title}</title>
                    <meta name="description" content={`Tic Tac Learn: Watch class ${this.state.video.grade} ${this.state.video.subject} ${this.state.video.video_title}`} />
                </Helmet>
                <div className="container">
                    <div className="sidebar-for-mobile">
                        <Sidebar
                            open={this.state.sidebarOpen}
                            onSetOpen={this.onSetSidebarOpen}
                            styles={{ sidebar: { background: "white" } }}
                            contentClassName="toggle-btn"
                            sidebarClassName="logo-sidebar"
                            overlayClassName="overlay-bg"
                            sidebar={
                                <div className=" col-sm-12 col-md-3 col-xs-12">
                                    <div className="sidebar-main">
                                       
                                        <div className="question-right-icon">
                                            <ul>
                                                <li>
                                                    {/* <Link to='/'> <img src={CopyIcon} alt="" /> <span>copy link</span>  </Link> */}
                                                    <CopyToClipboard text={window.location.href}
                                                        onCopy={() => { this.setState({ copiedLink: window.location.href, copied: true }); }}>

                                                        <Link to='#' onClick={() => {
                                                            this.openShareModal()
                                                            this.onSetSidebarOpen()
                                                        }} >
                                                            <img src={CopyIcon} alt="" />
                                                            <span>Share link</span>
                                                        </Link>
                                                    </CopyToClipboard>
                                                </li>
                                                <li>
                                                    <Link to='..'> <img src={BackIcon} alt="" /> <span> Go back </span> </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                        >
                            <button className="mobile-toggle-btn" onClick={() => this.onSetSidebarOpen(true)}>
                                <img src={ToggleIcon} />
                            </button>
                        </Sidebar>
                    </div>
                    <div className="row">
                        <div className=" col-sm-12 col-md-3 col-xs-12">
                            <div className="sidebar-main hide-desktop">
                                <div className="school-logo-box">
                                    <Link to='../../'>
                                        <img src={this.state.school.logo} alt="" />
                                        <p>{this.state.school.name}</p>
                                    </Link>
                                </div>
                                <div className="question-right-icon">
                                    <ul>
                                        <li>
                                            {/* <Link to='/'> <img src={CopyIcon} alt="" /> <span>copy link</span>  </Link> */}
                                            <CopyToClipboard text={window.location.href}
                                                onCopy={() => { this.setState({ copiedLink: window.location.href, copied: true }); }}>

                                                <Link to='#' onClick={this.openShareModal} >
                                                    <img src={CopyIcon} alt="" />
                                                    <span>Share link</span>
                                                </Link>
                                            </CopyToClipboard>
                                        </li>
                                        <li>
                                            <Link to='..'> <img src={BackIcon} alt="" /> <span> Go back </span> </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9 col-sm-12 col-xs-12">
                            <div className="school-logo-box mobile-view-logo">
                                {/* <img src={SchoolLogo} alt="" /> */}
                                <Link to='./'>
                                    <img src={this.state.school.logo} alt="" />
                                    <p>{this.state.school.name}</p>
                                </Link>

                            </div>
                            <div className="videos-details">
                                {/* <h1>लाम्बा छोटा <span>(1/3)</span></h1> */}
                                {/* <h1>{this.state.video.video_title}</h1> */}
                                <div className="video-player">
                                    <div className="video-title">
                                        <h1> <div dangerouslySetInnerHTML={{ __html: this.state.video.video_title }}></div></h1>
                                    </div>
                                    <ReactPlayer
                                        ref={this.ref}
                                        className="video-player-react"
                                        width="70%"
                                        height="400px"
                                        url={this.state.video.youtube_link}
                                        onEnded={this.onEnded}
                                        onPlay={this.handleClickFullscreen}
                                        controls
                                    />
                                    {this.state.isPlaylist ? <div className="d-flex justify-content-between video-next-prev-btn">
                                        <button className="btn btn-primary prev"> <img src={PrevStepIcon} alt="Previous" /> Previous</button>
                                        <button className="btn btn-primary next">  next <img src={NextStepIcon} alt="Next" /></button>
                                    </div>
                                        : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <Modal className="watching-video-modal" show={this.state.isOpen} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Thank you for watching Video.</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="share-icon">
                            <h5>You have finished watching Video</h5>
                            <br />
                            <h5>Please click Home button below to go back to your school Home Page.</h5>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Link to=".." className="btn btn-primary">Home</Link>
                        <Button variant="secondary" onClick={this.closeModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal> */}


                <Modal show={this.state.isShareOpen} onHide={this.closeShareModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Share</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="share-icon">
                            <ul>
                            <li> <Link onClick={() => this.handleCopied("Whatsapp")} to={{ pathname: "https://wa.me?text=" + this.state.copiedLink }} target="_blank" > <img src={PopupIcon1} alt="" /> <span>Whatsapp</span> </Link> </li>
                            <li> <Link onClick={() => this.handleCopied("Facebook")} to={{ pathname: "https://www.facebook.com/sharer/sharer.php?u=" + this.state.copiedLink }} target="_blank" > <img src={PopupIcon2} alt="" /> <span>Facebook</span></Link> </li>
                            <li> <Link onClick={() => this.handleCopied("Twitter")} to={{ pathname: "https://twitter.com/intent/tweet?url=" + this.state.copiedLink }} target="_blank" > <img src={PopupIcon4} alt="" /> <span>Twitter</span> </Link> </li>
                            </ul>
                        </div>
                        <div className="copied-link">
                            <Form.Group>
                                <Form.Control size="lg" type="text" placeholder="Large text" value={this.state.copiedLink} />
                                <Button variant="secondary" onClick={() => { toast('Link copied to clipboard'); this.closeModal; this.handleCopied("COPY"); }}>
                                    COPY
                                </Button>
                            </Form.Group>
                            {/* <Link to={{ pathname: this.state.copiedLink }} target="_blank" > {this.state.copiedLink} </Link> */}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeShareModal}>

                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }
}


export default Video;