import React, { Component } from 'react';
import { QuestionStartForm } from './QuestionStartForm';
import { QuestionForm } from './QuestionForm';
import { QuestionEndForm } from './QuestionEndForm';

export class Form extends Component {
    state = {
        step: 1,
    };

    nextStep = () => {
        const { step } = this.state;
        this.setState({ step: step + 1 });
    };

    prevStep = () => {
        const { step } = this.state;
        this.setState({ step: step - 1 });
    };

    inputChange = input => e => {
        this.setState({
            [input]: e.target.value
        });
    }    
    render() {
        const { step } = this.state;
        const { name, phone } = this.state;
        const values = { name, phone };
        switch (step) {
            case 1:
                return (
                    <QuestionStartForm
                        nextStep={this.nextStep}
                        inputChange={this.inputChange}
                        values={values}
                    />
                );
            case 2:
                return (
                    <QuestionForm
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        inputChange={this.inputChange}
                        values={values}
                    />
                );
            case 3:
                return(
                    <QuestionEndForm
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        inputChange={this.inputChange}
                    />
                );
        }
    }

}

export default Form