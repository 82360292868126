import React, { Component } from "react";
import './home-web.css';
import { Link, Redirect } from "react-router-dom";
import { getSchool } from "./services/schoolService";
import SchoolLogo from "./images/school-logo.png";
import Logo2 from "./images/ttl-logo.png";
import Slider from "react-slick";
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import HomePageGIF1 from "./images/home-page-1.gif";
import FooterLogo from "./images/TicTac-Learn-Website-Ver-1-03.png";
import WhyTicTacLearnIcon1 from "./images/ticon1.jpg";
import WhyTicTacLearnIcon2 from "./images/ticon2.jpg";
import WhyTicTacLearnIcon3 from "./images/ticon3.jpg";
import WhyTicTacLearnIcon4 from "./images/ticon4.jpg";
import TicTacLearnContent1 from "./images/TicTac-Learn-Website_element-22-2.png";
import TicTacLearnContent2 from "./images/TicTac-Learn-Website_element-24-1.png";
import TicTacLearnContent3 from "./images/TicTac-Learn-Website_element-19-1.png";
import TicTacLearnContent4 from "./images/TicTac-Learn-Website_element-21-2.png";
import SupportedBy1 from "./images/central.jpg";
import SupportedBy2 from "./images/goof.jpg";
import SupportedBy3 from "./images/logo-newww.jpg";
import Partners1 from "./images/TicTac-Learn-Website-29-1.png";
import Partners2 from "./images/TicTac-Learn-Website-28-1.png";
import Partners3 from "./images/TicTac-Learn-Website-27-1.png";
import Partners4 from "./images/TicTac-Learn-Website-26.png";
import Partners5 from "./images/TicTac-Learn-Website-25.png";
import Partners6 from "./images/TicTac-Learn-Website-24.png";
import Partners7 from "./images/square.jpg";
import Partners8 from "./images/22-23-1.png";
import Partners9 from "./images/TicTac-Learn-Website-Ver-1-06-3.jpg";
import Partners10 from "./images/TicTac-Learn-Website-Ver-1-07-4.jpg";
import Partners11 from "./images/TicTac-Learn-Website-Ver-1-09-3.jpg";
import Partners12 from "./images/TicTac-Learn-Website-Ver-1-16.png";
import Partners13 from "./images/TicTac-Learn-Website-Ver-1-17.png";
import Partners14 from "./images/TicTac-Learn-Website-Ver-1-18.png";
import Partners15 from "./images/TicTac-Learn-Website-Ver-1-19.png";
import ReactPlayer from "react-player";
import ClickHereImg1 from "./images/TicTac-Learn-Website_element-33-1.png";
import ClickHereImg2 from "./images/TicTac-Learn-Website_element-32-1.png";
import ClickHereImg3 from "./images/DIKSHA-logo-21.png";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { sendMail } from "./services/mailService";
import LoaderImg from "./images/ttl-loader.gif";
import ReactGA from 'react-ga';
import TopScrollImg from './images/next-arrow-white.png'
import CountUp from 'react-countup';
// import Homeweb from './Home-page-test';

class Homeweb extends Component {
    constructor(props) {
        super(props);
        this.state = {
            responseSent: false,
            emailError: false,
            isLoading: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {

        setTimeout(() => this.setState({ isLoading: false }), 3500)
        // console.log(this.state.isLoading);
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    handleSubmit(event) {
        const formdata = new FormData(event.target)
        const data = {}
        formdata.forEach(function (value, prop) {
            data[prop] = value
        })
        // console.log(data);


        // send mail
        sendMail(data).then(response => {
            this.setState(() => ({ responseSent: true }));
            // console.log(response);
        })
            .catch(error => {
                // console.log("Network Error");
                this.setState(() => ({ emailError: true }));
                // return <Redirect to='/404'  />
                // this.setState({ isValid: false });
            });

        event.preventDefault();
    }
    render() {

        const settings = {
            dots: false,
            infinite: true,
            arrow: true,
            speed: 500,
            slidesToShow: 6,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        const settingsPreview = {
            dots: false,
            infinite: true,
            arrow: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 1,
            centerPadding: '50px',
            Padding: '50px',
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        const settingsTestimonials = {
            dots: false,
            infinite: true,
            arrow: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: '50px',
            Padding: '50px',
        };
        return (
            <div className="home-page-web">
                <style
                    type="text/css"
                    data-type="vc_shortcodes-custom-css"
                    dangerouslySetInnerHTML={{
                        __html:
                            ".portal-header{display: none;}",
                    }}

                />
                <header id="Header" sticky="top">
                    <div className="wep-page-header">
                        <Navbar expand="lg" fixed="top">
                            <div className="container">
                                <Navbar.Brand id="logo" title="Tictac" href="https://tictaclearn.org">

                                    <img
                                        className="logo-main scale-with-grid"
                                        src={Logo2}
                                        data-retina="https://tictaclearn.org/wp-content/uploads/2020/03/TicTac-Learn-Website_element-23.png"
                                        data-height
                                        alt=""
                                        data-no-retina
                                    />

                                </Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="ml-auto">
                                        <Nav.Link href="#mfn-rev-slider">Home</Nav.Link>
                                        <Nav.Link href="#about">About us</Nav.Link>
                                        <Nav.Link href="#whylearn">Why TicTacLearn</Nav.Link>
                                        <Nav.Link href="#tictaclearncontent">TicTacLearn Content</Nav.Link>
                                        <Nav.Link href="#moments">TicTac Moments</Nav.Link>
                                        <Nav.Link href="#partners">Partners</Nav.Link>
                                        <Nav.Link href="#supported">Supported by</Nav.Link>
                                        <Nav.Link href="#contact">Contact Us</Nav.Link>
                                        <Nav.Link href="#faq">FAQs</Nav.Link>
                                    </Nav>
                                </Navbar.Collapse>
                            </div>
                        </Navbar>
                    </div>
                    <div className="mfn-main-slider" id="mfn-rev-slider">
                        <div className="home-banner">
                            <div className="banner-image">
                                <img src={HomePageGIF1} alt="" />
                            </div>
                            <div className="banner-contant">
                                <h1>Learning now more accessible than ever </h1>
                                <p> An online learning platform to access the finest learning </p>
                                <p>resources for school students.</p>
                            </div>
                        </div>
                    </div>
                </header>

                <section id="about" className="macking-learning-sec">
                    <div className="container">
                        <div className="macking-content">
                            <h2>Making learning accessible to all</h2>
                            <p>We firmly believe that education is the greatest equalizer of all! But unfortunately, we live in a world where access to quality learning resources is limited and comes with an associated price-tag, effectively perpetuating inequity in the society.</p>
                            <h3>We have set out to change that.</h3>
                            <p>At TICTACLEARN we believe that digital content has the power to reshape the learning spaces in the country and we want to take it one step closer to every learner, educator and parent. With that in mind we have created one of the largest open-source repositories of high-quality, curriculum aligned digital learning resources (~12,000 videos, aligned item bank, etc.) available for everyone, free of cost! Furthermore, we know that educational resources are most effective when it’s in the learners native language, and hence we aim to make our videos available in all major Indian languages.</p>
                        </div>
                    </div>
                </section>

                <section id="whylearn" className="Why-TicTacLearn">
                    <h3>Why TicTacLearn</h3>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-3 col-sm-3">
                                <div className="Why-TicTacLearn-box">
                                    <img src={WhyTicTacLearnIcon1} alt="" />
                                    <p>Curriculum aligned videos covering all topics</p>
                                    <div className="overlay">
                                        <h4>Curriculum aligned videos covering all topics</h4>
                                        <p>Our videos are mapped to the NCF syllabus and are properly structured, helping the students to find all topics at one place</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-3">
                                <div className="Why-TicTacLearn-box">
                                    <img src={WhyTicTacLearnIcon2} alt="" />
                                    <p>Short, animated & engaging videos</p>
                                    <div className="overlay">
                                        <h4>Short, animated & engaging videos</h4>
                                        <p>Our videos are 4-5 minutes long, and consist of animation to engage students and help them visualize a concept, rather than rote learning</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-3">
                                <div className="Why-TicTacLearn-box">
                                    <img src={WhyTicTacLearnIcon3} alt="" />
                                    <p>Pedagogically sound</p>
                                    <div className="overlay">
                                        <h4>Pedagogically sound</h4>
                                        <p>A lot of research has gone into creating the videos. For maximum effectiveness, the videos have been broken into 3 parts - An explanation video - to explain the concept ; Real life examples - for deeper understanding and practice ; Misconception video - for clarifying the commonly made  mistakes</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-3">
                                <div className="Why-TicTacLearn-box">
                                    <img src={WhyTicTacLearnIcon4} alt="" />
                                    <p>Free of cost</p>
                                    <div className="overlay">
                                        <h4>Free of cost</h4>
                                        <p>Most high quality digital content that is available comes with a high price tag and is hence out of reach for the underprivileged. TTL aims to remove this digital divide by offering all content free of cost of everyone!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="tictaclearncontent" className="tictaclearn-content">
                    <h2>TicTacLearn Content</h2>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-sm-6">
                                <div className="tictaclearn-content-box">
                                    <div className="tictaclearn-content-img-box">
                                        <img className="hover-before-img" src={TicTacLearnContent1} alt="" />
                                        <img className="hover-after-img" src={TicTacLearnContent2} alt="" />
                                    </div>
                                    <h2> <strong>Mathematics</strong> </h2>
                                    <p>Grade 1-10</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-6">
                                <div className="tictaclearn-content-box">
                                    <div className="tictaclearn-content-img-box">
                                        <img className="hover-before-img" src={TicTacLearnContent3} alt="" />
                                        <img className="hover-after-img" src={TicTacLearnContent4} alt="" />
                                    </div>
                                    <h2> <strong>Science</strong> </h2>
                                    <p>Grade 3-10</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="moments" className="quick-preview-sec">
                    <div className="quick-preview-content">
                        <h2>Quick Preview</h2>
                        <p>Our content is available in English, Hindi, Odia, Marathi and Telugu</p>
                    </div>
                    <div className="quick-preview-slider">
                        <div className="container">
                            <Slider {...settingsPreview}>
                                <div className="slider-videos">
                                    <h4 style={{ textAlign: "center" }}>
                                        English
                                            </h4>
                                    <ReactPlayer
                                        // width={370}
                                        height={226}
                                        width="90%"
                                        // height="550px"
                                        url="https://www.youtube.com/embed/HM0lOmQYF88"
                                        onEnded={this.openModal}
                                        controls
                                        muted
                                    />
                                    <h5 style={{ textAlign: "center", marginTop: "10px" }}>
                                        <a
                                            href="https://www.youtube.com/channel/UCgc6BPchRUum7ASV2QPGw2g?view_as=subscriber"
                                            target="_blank"
                                            rel="noopener"
                                        >
                                            View More
                                              </a>
                                    </h5>
                                </div>
                                <div className="slider-videos">
                                    <h4 style={{ textAlign: "center" }}>
                                        Hindi
                                                </h4>
                                    <ReactPlayer
                                        // width={370}
                                        height={226}
                                        width="90%"
                                        // height="550px"
                                        url="https://youtu.be/wQWutE78zO0"
                                        onEnded={this.openModal}
                                        controls
                                        muted
                                    />
                                    <h5 style={{ textAlign: "center", marginTop: "10px" }}>
                                        <a
                                            href="https://www.youtube.com/channel/UCkxfbAky2v0yXuKWOKCDy1w"
                                            target="_blank"
                                            rel="noopener"
                                        >
                                            View More
                                              </a>
                                    </h5>
                                </div>
                                <div className="slider-videos">
                                    <h4 style={{ textAlign: "center" }}>
                                        Telugu
                                                </h4>
                                    <ReactPlayer
                                        // width={370}
                                        height={226}
                                        width="90%"
                                        // height="550px"
                                        url="https://youtu.be/9YoFryuarfk"
                                        onEnded={this.openModal}
                                        controls
                                        muted
                                    />
                                    <h5 style={{ textAlign: "center", marginTop: "10px" }}>
                                        <a
                                            href="https://www.youtube.com/channel/UC9qLJLs51f97UVwzU19glnA"
                                            target="_blank"
                                            rel="noopener"
                                        >
                                            View More
                                              </a>
                                    </h5>
                                </div>
                                <div className="slider-videos">
                                    <h4 style={{ textAlign: "center" }}>
                                        Marathi
                                                </h4>
                                    <ReactPlayer
                                        // width={370}
                                        height={226}
                                        width="90%"
                                        // height="550px"
                                        url="https://youtu.be/rtf5wA0-iok"
                                        onEnded={this.openModal}
                                        controls
                                        muted
                                    />
                                    <h5 style={{ textAlign: "center", marginTop: "10px" }}>
                                        <a
                                            href="https://www.youtube.com/channel/UCHB-eNXs4hJB__1G1lOR38Q?view_as=subscriber"
                                            target="_blank"
                                            rel="noopener"
                                        >
                                            View More
                                              </a>
                                    </h5>
                                </div>
                                <div className="slider-videos">
                                    <h4 style={{ textAlign: "center" }}>
                                        Odia
                                                </h4>
                                    <ReactPlayer
                                        // width={370}
                                        height={226}
                                        width="90%"
                                        // height="550px"
                                        url="https://youtu.be/GbOaJFIlWQY"
                                        onEnded={this.openModal}
                                        controls
                                        muted
                                    />
                                    <h5 style={{ textAlign: "center", marginTop: "10px" }}>
                                        <a
                                            href="https://www.youtube.com/channel/UCl8_6ZBA370fTg2w6gGS6Mw"
                                            target="_blank"
                                            rel="noopener"
                                        >
                                            View More
                                              </a>
                                    </h5>
                                </div>
                            </Slider>
                        </div>
                    </div>
                    <div className="videos-bottom-box">
                        <div className="hover_box">
                            <a href="https://diksha.gov.in/explore/1?key=tic%20tac%20learn" target="_blank">
                                <div className="hover_box_wrapper">
                                    <img className="lazy visible_photo scale-with-grid" src={ClickHereImg1} />
                                    <img className="lazy hidden_photo scale-with-grid" src={ClickHereImg2} />
                                </div>
                            </a>
                        </div>
                        <div className="videos-bottom-img">
                            <div className="wpb_wrapper">
                                <p>to view us on Diksha</p>
                                <p> <img className="lazy aligncenter size-full wp-image-641 diksha" src={ClickHereImg3} /></p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="tictac-moments-sec">
                    <div className="tictac-moments-content">
                        <h2>TicTac Moments</h2>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 col-sm-4">
                                <div className="moments-counter">
                                    <CountUp delay={1} end={5} />
                                    <p>languages</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4">
                                <div className="moments-counter">
                                    <CountUp className="count-after-text" delay={1} end={875} />
                                    <p>hours of content</p>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4">
                                <div className="moments-counter">
                                    <CountUp delay={1} end={12000} />
                                    <p>videos</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="testimonials-sec">
                    <div className="testimonials-sec-content">
                        <h3>TicTacLearn Testimonials</h3>
                    </div>
                    <div className="ticTacLearn-testimonials-slider">
                        <div className="container">
                            <Slider {...settingsTestimonials}>
                                <div className="slide-content">
                                    <div className="slider-content">
                                        <p> "For younger children,
                                        TicTacLearn videos are very
                                        effective as they are able to
                                                listen and see."</p>
                                        <strong>
                                            Neelam, Maths Teacher, Class 4
                                          </strong>
                                    </div>
                                </div>
                                <div className="slide-content">
                                    <div className="slider-content">
                                        <p>
                                            "These videos are a great help.
                                            In case I am not able to
                                            understand anything during the
                                            class, I can always go home and
                                            watch the topic."
                                          </p>
                                        <strong>
                                            Sandhya, Student, Class 8
                                          </strong>
                                    </div>
                                </div>
                                <div className="slide-content">
                                    <div className="slider-content">
                                        <p>
                                            "TicTacLearn allows me to learn
                                            at my own pace and I can always
                                            brush up prerequisite concepts
                                            before moving ahead."
                                          </p>
                                        <strong>
                                            Rishi, Student, Class 6
                                          </strong>
                                    </div>
                                </div>
                                <div className="slide-content">
                                    <div className="slider-content">
                                        <p>
                                            "TicTacLearn helps us to support
                                            the learners beyond the school
                                            time; we direct them to a
                                            particular video before or after
                                            the class."
                                          </p>
                                        <strong>
                                            Nisha, Maths Teacher, Class 10
                                          </strong>
                                    </div>
                                </div>
                                <div className="slide-content">
                                    <div className="slider-content">
                                        <p>
                                            "TicTacLearn videos are like a
                                            friend or a personal teacher for
                                            the learners - always with them
                                            in time of need and support."
                                          </p>
                                        <strong>
                                            Madhu, Science Teacher, Class 5
                                          </strong>
                                    </div>
                                </div>
                                <div className="slide-content">
                                    <div className="slider-content">
                                        <p>
                                            "TicTacLearn content solves
                                            three critical dimensions of
                                            online learning - high quality
                                            content, engagement and access."
                                          </p>
                                        <strong>
                                            Ashish, Co-Founder, EdTech Company
                                          </strong>
                                    </div>
                                </div>
                                <div className="slide-content">
                                    <div className="slider-content">
                                        <p>
                                            "TicTacLearn content is
                                            open-source, bite-sized and
                                            available in regional Indian
                                            languages - bridges a critical
                                            gap in the ecosystem."
                                          </p>
                                        <strong>
                                            Manish, EdTech Consultant
                                          </strong>
                                    </div>
                                </div>
                                <div className="slide-content">
                                    <div className="slider-content">
                                        <p>
                                            “There are a lot of concepts we
                                            try to teach using textbooks
                                            but, if the child is not
                                            interested he/ she is not able
                                            to learn it. But through these
                                            videos they learn it
                                            automatically.”
                                          </p>
                                        <strong>
                                            Christina, Maths Teacher, Class 6
                                          </strong>
                                    </div>
                                </div>
                                <div className="slide-content">
                                    <div className="slider-content">
                                        <p>
                                            “Responses children give are
                                            very good. They even remember
                                            what they must learn in this
                                            chapter. They view these videos
                                            with a lot of glee and
                                            excitement.”
                                          </p>
                                        <strong>
                                            Asha, Science Teacher, Class 3
                                          </strong>
                                    </div>
                                </div>
                                <div className="slide-content">
                                    <div className="slider-content">
                                        <p>
                                            “Whenever we speak about the
                                            human body or the digestive
                                            system, the child's need is to
                                            be able to see what exactly it
                                            looks like. TicTacLean videos
                                            help fulfil these needs.”
                                          </p>
                                        <strong>
                                            Sabaul, Science Teacher, Class 9
                                          </strong>
                                    </div>
                                </div>

                            </Slider>
                        </div>
                    </div>
                </section>
                <section id="partners" className="Partners-slider-sec">
                    <div className="partners-content">
                        <h2>Partners</h2>
                    </div>
                    <div className="partners-slider">
                        <div className="container">
                            <Slider {...settings}>
                                <div className="slider-img">
                                    <img
                                        src={Partners1}
                                    />
                                </div>
                                <div className="slider-img">
                                    <img
                                        src={Partners2}
                                    />
                                </div>
                                <div className="slider-img">
                                    <img
                                        src={Partners3}
                                    />
                                </div>
                                <div className="slider-img">
                                    <img
                                        src={Partners4}
                                    />
                                </div>
                                <div className="slider-img">
                                    <img
                                        src={Partners5}
                                    />
                                </div>
                                <div className="slider-img">
                                    <img
                                        src={Partners6}
                                    />
                                </div>
                                <div className="slider-img">
                                    <img
                                        src={Partners7}
                                    />
                                </div>
                                <div className="slider-img">
                                    <img
                                        src={Partners8}
                                    />
                                </div>
                                <div className="slider-img">
                                    <img
                                        src={Partners9}
                                    />
                                </div>
                                <div className="slider-img">
                                    <img
                                        src={Partners10}
                                    />
                                </div>
                                <div className="slider-img">
                                    <img
                                        src={Partners11}
                                    />
                                </div>
                                <div className="slider-img">
                                    <img
                                        src={Partners12}
                                    />
                                </div>
                                <div className="slider-img">
                                    <img
                                        src={Partners13}
                                    />
                                </div>
                                <div className="slider-img">
                                    <img
                                        src={Partners14}
                                    />
                                </div>
                                <div className="slider-img">
                                    <img
                                        src={Partners15}
                                    />
                                </div>
                            </Slider>
                        </div>
                    </div>
                </section>
                <section id="supported" className="supported-by-sec">
                    <h3>Supported by</h3>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 col-sm-4">
                                <div className="supported-img-box">
                                    <img src={SupportedBy1} />
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4">
                                <div className="supported-img-box">
                                    <img src={SupportedBy2} />
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4">
                                <div className="supported-img-box">
                                    <img src={SupportedBy3} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="contact" className="stranger-form-sec">
                    <div className="stranger-form-content">
                        <h3>Don’t be a stranger, say HELLO!</h3>
                        <p>Are you a creator, educator, student, or just want to say hi?
                                Please reach out to us by filling in the form below and let us get in touch with you!</p>
                    </div>
                    <div className="hello-form cnnt-frm">
                        <Form onSubmit={this.handleSubmit}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6 col-sm-12">
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control required type="text" name="subject" placeholder="Subject" />
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <Form.Group controlId="formBasicPassword">
                                            <Form.Control required type="text" name="name" placeholder="Name" />
                                        </Form.Group>
                                    </div>

                                    <div className="col-md-6 col-sm-12">
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control required type="email" name="email" placeholder="Email Address" />
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6 col-sm-12">
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control required type="text" name="message" placeholder="Message" />
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                            <Button variant="primary" type="submit">
                                Submit
                                                  </Button>
                            {this.state.responseSent ?
                                <h4 className="row" style={{ color: 'green', width: '100%', placeContent: 'center' }}>Thank You for contacting us.</h4>
                                : null}
                            {this.state.emailError ?
                                <h4 className="row" style={{ color: 'red', width: '100%', placeContent: 'center' }}>Unable to Send Message. Please try again later.</h4>
                                : null}
                        </Form>
                    </div>
                </section>
                <section id="faq" className="faqs-sec">
                    <div className="faqs-content">
                        <h3>FAQs</h3>
                    </div>
                    <div className="faqs-accordion">
                        <div className="container">
                            <Accordion>
                                <div className="row">
                                    <div className="col-md-6">
                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                                    How are TicTacLearn videos made?
                                    </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="0">
                                                <Card.Body>
                                                    <p>○  All TicTacLearn videos are mapped to NCF syllabus and have been divided into topics as per NCERT.</p>
                                                    <p>○  Every Maths video is 4-5 minutes long. Every video topic in Maths has three videos –</p>
                                                    <p>■  Part 1 – Hook-Knowledge Capsule: designed to generate curiosity. The concept is introduced through a contextual problem</p>
                                                    <p>■  Part 2 – Illustrative Examples: designed to introduce varied, interesting and age appropriate examples</p>
                                                    <p>■  Part 3 – Misconceptions: This part of the video caters to misconceptions which students develop and common errors which they make. The target audience for these videos are teachers/parents. It aims to evaluate conceptual understanding rather than fast computational ability</p>
                                                    <ul>
                                                        <li>TicTacLearn’s Science videos have been designed maintaining that the learner is going to scientifically explore the concept rather than an adult based instruction.  The scientific methodology followed in the videos is ERAC. EXPLORATION, REFLECTION, APPLICATION and CONSOLIDATION. Each Video begins with an interesting hook which helps the child to explore the concept. The content makes the learner reflect on the observations with leading questions. The storyline guides the learner to apply the concept to local/global examples and predict the outcome. There is summary of the concepts ensuring that there is connectivity between the video topics of the same chapter. It relates to the textbook within the video in a manner which urges the learner to refer back to the textbook for knowledge and solutions. The videos are complementary to the textbooks, making the topic more visually enriching and interesting. Based on media habits and consumption, school exposure and lifestyle, there will be many things that the audience already has experience of. They may not know the science behind it. ICAP videos make this exploration and learning more interesting for the learner.</li>
                                                    </ul>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                                    Who can use the videos?
                                    </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body>
                                                    <p>○  Our videos are open source and we encourage viewers to use these in the way they deem fit. These videos can be used by –</p>
                                                    <p>■  Students, in- and outside of classroom</p>
                                                    <p>■  Teachers, as an aid in classrooms</p>
                                                    <p>■  Parents, to help their students visualize concepts and fill in their learning gaps</p>
                                                    <p>■  State governments</p>
                                                    <p>■  NGOs</p>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                                    I don’t see my language covered in TicTacLearn. How can I translate the videos to my language?
                                    </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="2">
                                                <Card.Body>
                                                    <p>○  We understand that teaching students in their native language is important. To value this, we are in the process of creating a translation tool that can be used by educators to translate existing open-source videos from one language to another. To know more, visit our translation tool here</p>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </div>
                                    <div className="col-md-6">
                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                                    Where can I find all TicTacLearn content?
                                    </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="3">
                                                <Card.Body>
                                                    <p>○  All TicTacLearn content is open source and resides on our YouTube Channels –</p>
                                                    <p>■  TicTacLearn Hindi</p>
                                                    <p>■  TicTacLearn English</p>
                                                    <p>■  TicTacLearn Telugu</p>
                                                    <p>■  TicTacLearn Oriya</p>
                                                    <p>■  TicTacLearn Marathi</p>
                                                    <p>○  You can also find our content on DIKSHA.</p>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card>
                                            <Card.Header>
                                                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                                                    Are there any copyright policies for TicTacLearn content? Can I edit/ re-upload/ sell the videos?
                                    </Accordion.Toggle>
                                            </Card.Header>
                                            <Accordion.Collapse eventKey="4">
                                                <Card.Body>
                                                    <p>○  All TicTacLearn content is registered under CC BY-NC SA. This means that they are open source, you can download, edit and use edited videos. Note that the edited videos will also have to lie under same license terms, and will be CC BY – SA NC. However, commercial use of videos is not allowed. If you downloaded our videos and made changes to it, you cannot earn money through them in any way.</p>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </div>
                                </div>
                            </Accordion>
                        </div>
                    </div>
                </section>
                <footer className="footer-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 col-sm-4">
                                <div className="footer-logo">
                                    <img src={FooterLogo} />
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4">
                                <div className="footer-menu1">
                                    <ul>
                                        <li> <a href="https://diksha.gov.in/explore/1?key=tic%20tac%20learn"> <strong>Find us on DIKSHA</strong> </a> </li>
                                        <li className="footer-contact-title"> <strong>Contact us</strong> </li>
                                        <li> <a href="mailto:tictaclearn@centralsquarefoundation.org"> tictaclearn@centralsquarefoundation.org</a> </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4">
                                <div className="footer-menu2">
                                    <h3>Our Content</h3>
                                    <ul>
                                        <li> <a target="_blank" href="https://www.youtube.com/channel/UCkxfbAky2v0yXuKWOKCDy1w" > Hindi </a> </li>
                                        <li> <a target="_blank" href="https://www.youtube.com/channel/UCgc6BPchRUum7ASV2QPGw2g?view_as=subscriber"> English </a> </li>
                                        <li> <a target="_blank" href="https://www.youtube.com/channel/UC9qLJLs51f97UVwzU19glnA?view_as=subscriber"> Telugu </a> </li>
                                        <li> <a target="_blank" href="https://www.youtube.com/channel/UCl8_6ZBA370fTg2w6gGS6Mw?view_as=subscriber"> Odia </a> </li>
                                        <li> <a target="_blank" href="https://www.youtube.com/channel/UCHB-eNXs4hJB__1G1lOR38Q?view_as=subscriber"> Marathi </a> </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="scroll-top">
                        <a href="#mfn-rev-slider"><img src={TopScrollImg} /></a>
                    </div>
                </footer>
            </div>
        );
    }
}

export default Homeweb;