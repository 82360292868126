import http from "./httpServices";

const apiEndPoint = http.API_URL + "api/video";

function videoUrl(slug) {
  return `${apiEndPoint}/${slug}/`;
}

// export function getSchools() {
//   return http.get(apiEndPoint);
// }

export function getVideo(slug) {
  return http.get(videoUrl(slug));
}
