import http from "./httpServices";

const apiEndPoint = http.API_URL + "api/school";

function schoolUrl(slug) {
  return `${apiEndPoint}/${slug}/`;
}

// export function getSchools() {
//   return http.get(apiEndPoint);
// }

export function getSchool(slug) {
  return http.get(schoolUrl(slug));
}
