import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import HomeIcon from '../images/home-ic.png';

export class QuestionEndForm extends Component {
    continue = e => {
        e.preventDefault();
        this.props.nextStep();
    };
    back = e => {
        e.preventDefault();
        this.props.prevStep();
    };
    render() {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <div className="start-question-content">
                                <div className="success-message">
                                    <h2>You have attempted 5/5 questions</h2>
                                    <h3>You answered 4 out of 5 questions correctly</h3>
                                    <p>Click on the link blow to see the correct answers</p>
                                    <div className="success-button">
                                        <button className="btn btn-primary" onClick={this.back}>Solutions</button>
                                    </div>
                                    {/* <div className="question-right-icon">
                                        <ul>
                                            <li>
                                                <Link to='/'> <img src={HomeIcon} alt="" /> <span> back to home </span> </Link>
                                            </li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default QuestionEndForm;