import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

import './App.css';
import './Responsive.css';
import ReactGA from 'react-ga';

// UA-97816677-1
// G-BEQ9MC0CXT
// UA-186036300-1
ReactGA.initialize('UA-186036300-1', {
  // debug: true,
  titleCase: false,
  gaOptions: {
      siteSpeedSampleRate: 100
    }
});

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

// ReactDOM.render(
//   <Hello />,
//   document.getElementById('hello')
// );
