import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { getSchool } from "./services/schoolService";
import SchoolLogo from './images/school-logo.png';
import Logo2 from './images/ttl-logo2.png';
import ReactGA from 'react-ga';
import  {Helmet} from 'react-helmet';


class Home extends Component {
    
    constructor(props) {
		super(props);
		this.state = {
        school: [],
        isValid: true
    };
	}
    async componentDidMount() {

            // console.log(this.state.isLoading);
            const disablePinchZoom = (e) => {
              if (e.touches.length > 1) {
                e.preventDefault()
              }
            }
            document.addEventListener("touchmove", disablePinchZoom, { passive: false })
        
        ReactGA.set({ 'dimension1': this.props.match.params.school });
        ReactGA.set({ 'dimension2': 'School' });
        ReactGA.event({
            'category': 'School',
            'action': 'School HomePage Visited'
        });
        ReactGA.pageview(window.location.pathname + window.location.search);

        // const schoolLogo = '../images/ttl-logo2.png';
        // const schoolName = 'Maharishi Vidyamandir';
        // const schoolDescription = 'Maharishi Vidyamandir trust has been established in 1885 by Rajanna Lingu Polas, Balkrishna Sayanna Motadu, B. Dastur, Dorabji Booty, Gangaram Bhau Mhaske and Mahatma Jyotiba Phule along with other socially committed people, to create social awareness and to spread the light of education for socially and financially backward sections of the society. The society takes pride in the fact that noted social reformer and educationalist Acharya Atre worked as Head Master for Camp Education Society for about two decades.';
        
        await getSchool(this.props.match.params.school).then(response => {
            this.setState(() => ({ school: response.data}));
            // console.log(response);
        })
        .catch(error => { 
            console.log("Page Not Found");
            this.setState({ isValid: false });
        });
        
        // this.setState({ SchoolLogo: schoolLogo, SchoolName: schoolName, SchoolDescription: schoolDescription })
    };
    render() {
        document.title = 'TicTacLearn|School|' + this.state.school.name;

        if (!this.state.isValid) 
            return <Redirect to='..'  />
            
        return (
            <div className="home-page-main school-page">
                <Helmet>
                    <title>{'TicTacLearn|School|' + this.state.school.name}</title>
                    <meta name="description" content={`Tic Tac Learn: ${this.state.school.name}`} />
                </Helmet>
                <div className="container">
                    <div className="row">
                        <div className=" col-sm-12 col-md-12 col-xs-12">
                            <div className="sidebar-main">
                                <div className="school-logo-box">
                                    {/* <img src={this.state.SchoolLogo} alt="" /> */}
                                    <img src={this.state.school.logo} alt="" />
                                    <p>{this.state.school.name}</p>
                                </div>
                            </div>
                            <div className="school-bottom-content">
                                {/* <p>{this.state.SchoolDescription}</p> */}
                                <p><div dangerouslySetInnerHTML={{__html: this.state.school.description}}></div> </p>
                                <Link to={{ pathname: this.props.match.url.replace(/\/$/, "") + '/content' }} className="start-btn btn"> Start </Link>
                            </div>
                            <div className="tic-tca-logo-content">
                                <img src={Logo2} alt="Tic Tac Learn" />
                                <p>Making learning accessible to all.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default Home;