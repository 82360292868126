import http from "./httpServices";

const apiEndPoint = http.API_URL + "api/playlist";
// const apiEndPoint = "http://157.245.96.72:8081/api/playlist";

function playListUrl(slug) {
  return `${apiEndPoint}/${slug}/`;
}

// export function getSchools() {
//   return http.get(apiEndPoint);
// }

export function getplayList(slug) {
  return http.get(playListUrl(slug));
}
