import http from "./httpServices";

const apiEndPoint = http.API_URL + "api/practice";

function practiceQAUrl(slug) {
  return `${apiEndPoint}/${slug}/`;
}


export function getPracticeQA(slug) {
  return http.get(practiceQAUrl(slug));
}