import axios from "axios";

import { toast } from "react-toastify";
// import logger from "./logService";

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const API_URL = "https://api.tictaclearn.org/";
// const API_URL = "http://143.110.254.46:8089/";
// const API_URL = "http://143.110.242.59:8001/";
// const API_URL = "http://157.245.96.72:8081/";

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    // logger.log(error);
    toast.error("An unexpected error occured.");
  }
  return Promise.reject(error);
});

function setJwt(jwt) {
  axios.defaults.headers.common["x-auth-token"] = jwt;
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  API_URL: API_URL,
  //   setJwt
};
