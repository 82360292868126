import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import QuestionSetup from './component/QuestionSetup';
import SchoolLogo from './images/school-logo.png';
import CopyIcon from './images/color-share-ic.png';
// import HomeIcon from './images/home-ic.png';
import BackIcon from './images/back-icon.png';
import { getSchool } from "./services/schoolService";
import {CopyToClipboard} from 'react-copy-to-clipboard';



class MultipleQuestion extends Component {
    state = {
        school: [],
        copied: false
    }

    async componentDidMount(){
        
        await getSchool(this.props.match.params.school).then(response => {
            this.setState(() => ({ school: response.data}));
            console.log(response);
        })
        .catch(error => { 
            console.log("Page Not Found");
            this.setState({ isValid: false });
        });

    }
    render() {
        return (
            <div className="home-page-main">
                <div className="container">
                    <div className="row">
                        <div className=" col-sm-12 col-md-3 col-xs-12">
                            <div className="sidebar-main">
                                <div className="school-logo-box">
                                    <img src={this.state.school.logo} alt="" />
                                    <p>{this.state.school.name}</p>
                                </div>
                                <div className="question-right-icon">
                                <ul>
                                    <li>
                                    <CopyToClipboard text={window.location.href}
                                        onCopy={() => this.setState({copied: true})}>
                                        <Link to='#'>
                                            <img src={CopyIcon} alt="" />
                                            {this.state.copied ? <span style={{color: 'red'}}>Link Copied.</span> : <span>Share link</span>}
                                        </Link>
                                    </CopyToClipboard>
                                    </li>
                                    <li>
                                        <Link to='..'> <img src={BackIcon} alt="" /> <span> Go back </span> </Link>
                                    </li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-9 col-sm-12 col-xs-12">
                            <div className="question-form-main">
                                <QuestionSetup />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default MultipleQuestion;