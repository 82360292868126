import React, { Component, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import ModalImage from "react-modal-image";
import QuestionSetup from './component/QuestionSetup';
import CloseIcon from './images/close-icon.png';
import { postShareAnalytics, postPracticeAnalytics } from "./services/analyticService";
import CopyIcon from './images/color-share-ic.png';
// import HomeIcon from './images/home-ic.png';
import BackIcon from './images/back-icon.png';
import NextStepIcon from './images/next-arrow-white.png';
import PrevStepIcon from './images/previous-arrow-white.png';
import { getSchool } from "./services/schoolService";
import { getPracticeQA } from "./services/practiceQAService";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PopupIcon1 from './images/whatup-ic1.svg';
import PopupIcon2 from './images/facebook.svg';
import PopupIcon4 from './images/twitter (1).svg';
import ReactGA from 'react-ga';
import toast, { toastConfig } from 'react-simple-toasts';
import Sidebar from "react-sidebar";
import ToggleIcon from './images/toggle.png';
import { Helmet } from 'react-helmet';

class PracticeQA extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentQuestion: -1,
            showScore: false,
            score: 0,
            attempted: 0,

            school: [],
            questions: [],
            responses: [],
            showQA: false,
            showSolutions: false,
            copied: false,
            isValid: true,
            isShareOpen: false,
            sidebarOpen: false
        }

        this.nextStep = this.nextStep.bind(this);
        this.prevStep = this.prevStep.bind(this);
        this.handleAnswerOptionClick = this.handleAnswerOptionClick.bind(this);
        this.calculateScore = this.calculateScore.bind(this);
        this.handleSolutionBtn = this.handleSolutionBtn.bind(this);
        this.handleCopied = this.handleCopied.bind(this);
        this.scrollTop = this.scrollTop.bind(this);
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);

        toastConfig({
            time: 3000,
            className: 'my-toast-message',
            position: 'left'
        });
    }
    onSetSidebarOpen(open) {
        this.setState({ sidebarOpen: open });
    }
    scrollTop() {
        window.scrollTo({
            top: 0
        });
    }

    nextStep() {

        // uncheck previously selected checkboxes
        try {
            document.getElementById('formHorizontalRadios1').checked = false;
            document.getElementById('formHorizontalRadios2').checked = false;
            document.getElementById('formHorizontalRadios3').checked = false;
            document.getElementById('formHorizontalRadios4').checked = false;
        }
        catch { }

        this.scrollTop();

        const { currentQuestion } = this.state;
        this.setState({ currentQuestion: currentQuestion + 1 });

        const nextQuestion = this.state.currentQuestion + 1;

        // console.log('nextStep ', nextQuestion);

        // inintialize the first Q/A
        if (nextQuestion == 0) {
            this.setState({ currentQuestion: 1, showQA: true });
        }
        // if has questions in array
        else if (nextQuestion <= this.state.questions.length) {
            this.setState({ currentQuestion: nextQuestion });
        }
        // end of Q/A
        else {
            this.setState({ showQA: false });
        }
    };

    prevStep() {
        // uncheck previously selected checkboxes
        try {
            document.getElementById('formHorizontalRadios1').checked = false;
            document.getElementById('formHorizontalRadios2').checked = false;
            document.getElementById('formHorizontalRadios3').checked = false;
            document.getElementById('formHorizontalRadios4').checked = false;
        }
        catch { }

        this.scrollTop();

        const { currentQuestion } = this.state;
        this.setState({ currentQuestion: currentQuestion - 1 });

        const prevQuestion = this.state.currentQuestion - 1;

        // console.log('prevStep ', prevQuestion);

        if (prevQuestion > 0) {
            this.setState({ currentQuestion: prevQuestion, showQA: true });
        } else {
            this.setState({ currentQuestion: -1, showQA: false });
        }
    };

    async calculateScore() {
        const { questions } = this.state;
        let { score, attempted } = this.state;

        // if question is attempted
        await questions.map(el =>
        (el.selectedOption !== undefined ?
            attempted = attempted + 1 :
            null
        ))

        // if question is correct
        await questions.map(el =>
        (el.isCorrect === true ?
            score = score + 1 :
            null
        ))
        ReactGA.set({ 'dimension11': attempted });
        ReactGA.set({ 'dimension12': score });
        ReactGA.event({
            'category': 'Practice Q/A',
            'action': 'Q/A Response Submitted',
            'label': 'Q/A Response Submitted',
            // 'value': window.location.pathname + window.location.search
        });

        this.setState({ currentQuestion: 999, showQA: false, score: score, attempted: attempted });
        // console.log(this.state);
        let data = {};
        this.state.questions.map(ques =>
        (data.question_setId = this.props.match.params.content_id,
            data.qNo = ques.qNo,
            data.selected_option = ques.selectedOption,
            data.correct_response = ques.isCorrect === true ? 1 : 0,
            data.school_name = this.props.match.params.school,

            ques.selectedOption !== undefined ?
                (
                    postPracticeAnalytics(data).then(response => {
                        // console.log(response);
                    })
                        .catch(error => {
                            console.log("Network Error");
                        })
                ) : null)
        );


    };

    async handleAnswerOptionClick(quesID, selectedOption) {


        // set user selected option
        await this.setState({
            questions: this.state.questions.map(el => (el.id === quesID ? Object.assign({}, el, { selectedOption: selectedOption }) : el))
        });

        // set isCorrect true if answer is correct
        await this.setState({
            questions: this.state.questions.map(el => (el.id === quesID && el.answer_no == selectedOption ? Object.assign({}, el, { isCorrect: true }) : el))
        });




        // event.preventDefault();
    };

    handleSolutionBtn() {

        // show Correct Answers
        this.setState({ showSolutions: true, currentQuestion: 1, showQA: true });

    };

    // handleCopied() {
    //     // send to Google Analytics
    //     // ReactGA.set({ 'copiedLink': window.location.pathname + window.location.search });
    //     ReactGA.set({ 'dimension4': window.location.href });
    //     ReactGA.event({
    //         'category': 'Link Copied',
    //         'action': 'Practice Q/A Link Copied',
    //         'label': 'Practice Q/A Link Copied',
    //         'value': window.location.href
    //     });

    //     let data = {
    //         url: window.location.href,
    //         slug: this.props.match.params.content_id,
    //         school_name: this.props.match.params.school,
    //     };
    //     postShareAnalytics(data).then(response => {
    //         // console.log(response);
    //     })
    //         .catch(error => {
    //             console.log("Network Error");
    //             // return <Redirect to='/404'  />
    //             // this.setState({ isValid: false });
    //         });
    // };

    handleCopied(type) {
        // send to Google Analytics
        console.log("type ---->", type);
        ReactGA.set({ 'dimension4': this.state.copiedLink });
        ReactGA.event({
            'category': 'Link Copied',
            'action': 'Content Link Copied',
            'label': 'Content Link Copied',
            'value': this.state.copiedLink
        });
        
      
        const urls = window.location.href.split( 'p/' )[1];
        let data = {
            url: this.state.copiedLink,
            slug:urls ,
            content_type:'pl',
            school_name: this.props.match.params.school,
            type: type
        };
        // console.log(this.state);

        postShareAnalytics(data).then(response => {
            // console.log(response);
        })
            .catch(error => {
                console.log("Network Error");
                // return <Redirect to='/404'  />
                // this.setState({ isValid: false });
            });
        console.log(this.state);
    };

    openShareModal = () => { this.setState({ isShareOpen: true }); this.onSetSidebarOpen(false); }
    closeShareModal = () => this.setState({ isShareOpen: false });

    async componentDidMount() {

        ReactGA.set({ 'dimension1': this.props.match.params.school });
        ReactGA.set({ 'dimension2': 'Practice' });
        ReactGA.event({
            'category': 'Practice Q/A',
            'action': 'Practice Q/A Visited'
        });
        ReactGA.pageview(window.location.pathname + window.location.search);

        await getSchool(this.props.match.params.school).then(response => {
            this.setState(() => ({ school: response.data }));
            // console.log(response);
        })
            .catch(error => {
                console.log("Page Not Found");
                this.setState({ isValid: false });
            });

        await getPracticeQA(this.props.match.params.content_id).then(response => {
            this.setState(() => ({ questions: response.data }));
            // console.log(response);
        })
            .catch(error => {
                console.log("Page Not Found");
                this.setState({ isValid: false });
            });



        // init question no from url only if passed in url
        var qno = this.props.match.params.qno;
        if (qno) {
            if (qno == 'start') {
                qno = -1;
                this.setState({ showQA: false });
            }
            else if (qno == 'end') {
                qno = 999;
                this.setState({ showQA: false });
            }
            this.setState({ currentQuestion: parseInt(qno) });
        } else {
            qno = -1;
            this.setState({ showQA: false });
        }



        // alert(this.state.currentQuestion);
        // console.log(this.state);
        // alert(this.state.questions[this.state.currentQuestion - 1].question_text);
    }

    render() {
        document.title = 'TicTacLearn|Practice Q/A';
        if (!this.state.isValid)
            return <Redirect to='..' />
        console.log("school ---->", this.state.questions);
        return (

            <div className="home-page-main practice-quetions">
                <Helmet>
                    <title>{'TicTacLearn|Practice Q/A' + this.state?.questions[0]?.video_title}</title>
                    <meta name="description" content={`TicTacLearn|Practice Q/A ${this.state?.questions[0]?.grade} ${this.state?.questions[0]?.video_topic_name} ${this.state?.questions[0]?.video_title}`} />
                </Helmet>
                <div className="container">
                    <div className="sidebar-for-mobile">
                        <Sidebar
                            open={this.state.sidebarOpen}
                            onSetOpen={this.onSetSidebarOpen}
                            styles={{ sidebar: { background: "white" } }}
                            contentClassName="toggle-btn"
                            sidebarClassName="logo-sidebar"
                            overlayClassName="overlay-bg"
                            sidebar={
                                <div className=" col-sm-12 col-md-3 col-xs-12">
                                    <div className="sidebar-main">
                                        <div className="close-sidebar">
                                            <button className="sidebar-close" onClick={() => this.onSetSidebarOpen(false)} > <img src={CloseIcon} alt="Close" /> </button>
                                        </div>
                                      
                                        <div className="question-right-icon">
                                            <ul>
                                                <li>
                                                    <CopyToClipboard text={window.location.href}
                                                        onCopy={() => { this.setState({ copiedLink: window.location.href, copied: true }); this.handleCopied(); }}>
                                                        <Link to='#' onClick={this.openShareModal} >
                                                            <img src={CopyIcon} alt="" />
                                                            <span>Share link</span>
                                                        </Link>
                                                    </CopyToClipboard>
                                                </li>
                                                <li>
                                                    <Link to='..'> <img src={BackIcon} alt="" /> <span> Go back </span> </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                        >
                            <button className="mobile-toggle-btn" onClick={() => this.onSetSidebarOpen(true)}>
                                <img src={ToggleIcon} />
                            </button>
                        </Sidebar>
                    </div>
                    <div className="row">
                        <div className=" col-sm-12 col-md-3 col-xs-12">
                            <div className="sidebar-main hide-desktop">
                                <div className="school-logo-box" style={{ marginBottom:10 }}>
                                    <Link to={`/${this.state.school.slug_url}`}>
                                        <img src={this.state.school.logo} alt="" />
                                        <p>{this.state.school.name}</p>
                                    </Link>
                                </div>
                                <div className="question-right-icon">
                                    <ul>
                                        <li>
                                            <CopyToClipboard text={window.location.href}
                                                onCopy={() => { this.setState({ copiedLink: window.location.href, copied: true })}}>
                                                <Link to='#' onClick={this.openShareModal} >
                                                    <img src={CopyIcon} alt="" />
                                                    <span>Share link</span>
                                                </Link>
                                            </CopyToClipboard>
                                        </li>
                                        <li>
                                            <Link to='..'> <img src={BackIcon} alt="" /> <span> Go back </span> </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9 col-sm-12 col-xs-12">
                            <div className="question-form-main">
                                {/* <QuestionSetup /> */}

                                {this.state.currentQuestion == -1 ? (

                                    <div className="start-question">
                                        <div className="question-head">
                                            <div className="school-logo-box mobile-view-logo" style={{ marginBottom:10 }}>
                                                {/* <img src={SchoolLogo} alt="" /> */}
                                               <Link to={`/${this.state.school.slug_url}`}>
                                                    <img src={this.state.school.logo} alt="" />
                                                    <p>{this.state.school.name}</p>
                                                </Link>

                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12 col-xs-12" >
                                                    <div className="question-heading">
                                                        <h1><b> <div dangerouslySetInnerHTML={{ __html: this.state.questions[0] && this.state.questions[0].video_topic_name }}></div>  </b></h1>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="start-question-content">
                                                        <div className="start-question-content-text">
                                                            <h4>Ready to practice?</h4>
                                                            <h3>Click ‘Start’ to begin!</h3>
                                                            <h2><span>{this.state.questions.length}</span> multiple choice questions </h2>
                                                        </div>
                                                        <div className="text-center">
                                                            <button className="btn btn-primary" onClick={this.nextStep}>Start</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                ) :
                                    null
                                }

                                {this.state.currentQuestion > this.state.questions.length ? (

                                    <div>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-md-12 col-sm-12">
                                                    <div className="school-logo-box mobile-view-logo" style={{ marginBottom:10 }}> 
                                                        {/* <img src={SchoolLogo} alt="" /> */}
                                                     <Link to={`/${this.state.school.slug_url}`} >
                                                            <img src={this.state.school.logo} alt="" />
                                                            <p>{this.state.school.name}</p>
                                                        </Link>

                                                    </div>
                                                    <div className="start-question-content">
                                                        {this.state.showSolutions ?
                                                            (<div className="success-message">
                                                                <p>Hope you found the answers you were looking for</p>
                                                                <p>Please Click on Home button below to go back to your school home page</p>
                                                                <div className="success-button">
                                                                    <Link to=".." className="btn btn-primary">Home</Link>
                                                                </div>
                                                            </div>)
                                                            : (<div className="success-message">
                                                                <h2>You Attempted  {this.state.attempted} Out Of    {this.state.questions.length} Questions</h2>
                                                                <h3>You Answered {this.state.score} / {this.state.questions.length} Questions Correctly</h3>
                                                                {/* <p>Click on the link below to see the correct answers</p> */}
                                                                <div className="success-button">
                                                                    <button className="btn btn-primary" onClick={this.handleSolutionBtn}>See Answers</button>
                                                                </div>
                                                                {/* <p>Click below to go to the Home Page</p>
                                                                <div className="success-button">
                                                                    <Link to=".." className="btn btn-primary">Home</Link>
                                                                </div> */}
                                                            </div>)
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                ) :
                                    null
                                }

                                {this.state.showQA ? (

                                    <div className="question-form">
                                        <div className="row">
                                            <div className="col-md-12 col-sm-12 col-xs-12">
                                                <div className="school-logo-box mobile-view-logo" style={{ marginBottom:10 }}>
                                                    {/* <img src={SchoolLogo} alt="" /> */}
                                                <Link to={`/${this.state.school.slug_url}`}>
                                                        <img src={this.state.school.logo} alt="" />
                                                        <p>{this.state.school.name}</p>
                                                    </Link>

                                                </div>
                                                <div className="question-heading">
                                                    <h1><b> <div dangerouslySetInnerHTML={{ __html: this.state.questions[this.state.currentQuestion - 1].video_topic_name }}></div> </b></h1>
                                                </div>
                                            </div>

                                            <div className="col-md-12 col-sm-12">

                                                <div className="start-question-content">

                                                    <div className="start-question-box">
                                                        <div className="question-Img">
                                                            <h2 className="text-question-main"><span>Q({this.state.currentQuestion})</span> <div dangerouslySetInnerHTML={{ __html: this.state.questions[this.state.currentQuestion - 1].question_text }}></div> </h2>
                                                            {this.state.questions[this.state.currentQuestion - 1].question_image ? (
                                                                // <img src={this.state.questions[this.state.currentQuestion - 1].question_image} alt="" />
                                                                <ModalImage small={this.state.questions[this.state.currentQuestion - 1].question_image} large={this.state.questions[this.state.currentQuestion - 1].question_image} />
                                                            ) : null
                                                            }

                                                        </div>
                                                        <div className="question-radio-group">
                                                            <Form>
                                                                <div className="row">
                                                                    <div className={
                                                                        "col-md-12 col-sm-12 col-xs-12 radio-item " +
                                                                        (this.state.showSolutions && this.state.questions[this.state.currentQuestion - 1].answer_no === 1 ? " correct-answer-hl " : null)
                                                                        + (this.state.showSolutions && this.state.questions[this.state.currentQuestion - 1].answer_no != 1 && this.state.questions[this.state.currentQuestion - 1].selectedOption === 1 ? " wrong-answer-hl " : null)}
                                                                    >
                                                                        <div className="radio-with-imgpopup" style={{ display: this.state.questions[this.state.currentQuestion - 1].option1 == 'NA' ? 'none' : null }}>
                                                                            <input checked={this.state.questions[this.state.currentQuestion - 1].selectedOption === 1} onClick={() => this.handleAnswerOptionClick(this.state.questions[this.state.currentQuestion - 1].id, 1)} type="radio"></input>
                                                                            <label><div className="option-images">(1) {(this.state.questions[this.state.currentQuestion - 1].option1_image ? <ModalImage small={this.state.questions[this.state.currentQuestion - 1].option1_image} large={this.state.questions[this.state.currentQuestion - 1].option1_image} /> : <div className="option-number-space" dangerouslySetInnerHTML={{ __html: this.state.questions[this.state.currentQuestion - 1].option1 }}></div>)}</div></label>
                                                                        </div>
                                                                        {/* <Form.Check
                                                                            type="radio"
                                                                            style={{ display: this.state.questions[this.state.currentQuestion - 1].option1 == 'NA' ? 'none' : null }}
                                                                            onClick={() => this.handleAnswerOptionClick(this.state.questions[this.state.currentQuestion - 1].id, 1)}
                                                                            label={<>(1) {(this.state.questions[this.state.currentQuestion - 1].option1_image ? <ModalImage small={this.state.questions[this.state.currentQuestion - 1].option1_image} large={this.state.questions[this.state.currentQuestion - 1].option1_image} /> : <div className="option-number-space" dangerouslySetInnerHTML={{ __html: this.state.questions[this.state.currentQuestion - 1].option1 }}></div>)}</>}
                                                                            name="formHorizontalRadios"
                                                                            id="formHorizontalRadios1"
                                                                            onChange={() => null}
                                                                            checked={this.state.questions[this.state.currentQuestion - 1].selectedOption === 1}
                                                                        /> */}
                                                                    </div>
                                                                    <div className={
                                                                        "col-md-12 col-sm-12 col-xs-12 radio-item " +
                                                                        (this.state.showSolutions && this.state.questions[this.state.currentQuestion - 1].answer_no === 2 ? " correct-answer-hl " : null)
                                                                        + (this.state.showSolutions && this.state.questions[this.state.currentQuestion - 1].answer_no != 2 && this.state.questions[this.state.currentQuestion - 1].selectedOption === 2 ? " wrong-answer-hl " : null)}

                                                                    >
                                                                        <div className="radio-with-imgpopup" style={{ display: this.state.questions[this.state.currentQuestion - 1].option2 == 'NA' ? 'none' : null }}>
                                                                            <input checked={this.state.questions[this.state.currentQuestion - 1].selectedOption === 2} onClick={() => this.handleAnswerOptionClick(this.state.questions[this.state.currentQuestion - 1].id, 2)} type="radio"></input>
                                                                            <label><div className="option-images">(2) {(this.state.questions[this.state.currentQuestion - 1].option2_image ? <ModalImage small={this.state.questions[this.state.currentQuestion - 1].option2_image} large={this.state.questions[this.state.currentQuestion - 1].option2_image} /> : <div className="option-number-space" dangerouslySetInnerHTML={{ __html: this.state.questions[this.state.currentQuestion - 1].option2 }}></div>)}</div></label>
                                                                        </div>
                                                                        {/* <Form.Check
                                                                            type="radio"
                                                                            style={{ display: this.state.questions[this.state.currentQuestion - 1].option2 == 'NA' ? 'none' : null }}
                                                                            onClick={() => this.handleAnswerOptionClick(this.state.questions[this.state.currentQuestion - 1].id, 2)}
                                                                            // label={"(B) " + this.state.questions[this.state.currentQuestion - 1].option2}
                                                                            label={<>(2) {(this.state.questions[this.state.currentQuestion - 1].option2_image ? <ModalImage small={this.state.questions[this.state.currentQuestion - 1].option2_image} large={this.state.questions[this.state.currentQuestion - 1].option2_image} /> : <div className="option-number-space" dangerouslySetInnerHTML={{ __html: this.state.questions[this.state.currentQuestion - 1].option2 }}></div>)}</>}
                                                                            name="formHorizontalRadios"
                                                                            id="formHorizontalRadios2"
                                                                            onChange={() => null}
                                                                            checked={this.state.questions[this.state.currentQuestion - 1].selectedOption === 2}
                                                                        /> */}
                                                                    </div>
                                                                    <div className={
                                                                        "col-md-12 col-sm-12 col-xs-12 radio-item " +
                                                                        (this.state.showSolutions && this.state.questions[this.state.currentQuestion - 1].answer_no === 3 ? " correct-answer-hl " : null)
                                                                        + (this.state.showSolutions && this.state.questions[this.state.currentQuestion - 1].answer_no != 3 && this.state.questions[this.state.currentQuestion - 1].selectedOption === 3 ? " wrong-answer-hl " : null)}
                                                                    >
                                                                        <div className="radio-with-imgpopup" style={{ display: this.state.questions[this.state.currentQuestion - 1].option3 == 'NA' ? 'none' : null }}>
                                                                            <input checked={this.state.questions[this.state.currentQuestion - 1].selectedOption === 3} onClick={() => this.handleAnswerOptionClick(this.state.questions[this.state.currentQuestion - 1].id, 3)} type="radio"></input>
                                                                            <label><div className="option-images">(3) {(this.state.questions[this.state.currentQuestion - 1].option3_image ? <ModalImage small={this.state.questions[this.state.currentQuestion - 1].option3_image} large={this.state.questions[this.state.currentQuestion - 1].option3_image} /> : <div className="option-number-space" dangerouslySetInnerHTML={{ __html: this.state.questions[this.state.currentQuestion - 1].option3 }}></div>)}</div></label>
                                                                        </div>
                                                                        {/* <Form.Check
                                                                            type="radio"
                                                                            style={{ display: this.state.questions[this.state.currentQuestion - 1].option3 == 'NA' ? 'none' : null }}
                                                                            onClick={() => this.handleAnswerOptionClick(this.state.questions[this.state.currentQuestion - 1].id, 3)}
                                                                            // label={"(C) " + this.state.questions[this.state.currentQuestion - 1].option3}
                                                                            label={<>(3) {(this.state.questions[this.state.currentQuestion - 1].option3_image ? <ModalImage small={this.state.questions[this.state.currentQuestion - 1].option3_image} large={this.state.questions[this.state.currentQuestion - 1].option3_image} /> : <div className="option-number-space" dangerouslySetInnerHTML={{ __html: this.state.questions[this.state.currentQuestion - 1].option3 }}></div>)}</>}
                                                                            // label={<>(C) <img className="option-img" src={this.state.questions[this.state.currentQuestion - 1].option3_image}/></>}
                                                                            name="formHorizontalRadios"
                                                                            id="formHorizontalRadios3"
                                                                            onChange={() => null}
                                                                            checked={this.state.questions[this.state.currentQuestion - 1].selectedOption === 3}
                                                                        /> */}
                                                                    </div>
                                                                    <div className={
                                                                        "col-md-12 col-sm-12 col-xs-12 radio-item " +
                                                                        (this.state.showSolutions && this.state.questions[this.state.currentQuestion - 1].answer_no === 4 ? " correct-answer-hl " : null)
                                                                        + (this.state.showSolutions && this.state.questions[this.state.currentQuestion - 1].answer_no != 4 && this.state.questions[this.state.currentQuestion - 1].selectedOption === 4 ? " wrong-answer-hl " : null)}
                                                                    >
                                                                        <div className="radio-with-imgpopup" style={{ display: this.state.questions[this.state.currentQuestion - 1].option4 == 'NA' ? 'none' : null }}>
                                                                            <input checked={this.state.questions[this.state.currentQuestion - 1].selectedOption === 4} onClick={() => this.handleAnswerOptionClick(this.state.questions[this.state.currentQuestion - 1].id, 4)} type="radio"></input>
                                                                            <label><div className="option-images">(4) {(this.state.questions[this.state.currentQuestion - 1].option4_image ? <ModalImage small={this.state.questions[this.state.currentQuestion - 1].option4_image} large={this.state.questions[this.state.currentQuestion - 1].option4_image} /> : <div className="option-number-space" dangerouslySetInnerHTML={{ __html: this.state.questions[this.state.currentQuestion - 1].option4 }}></div>)}</div></label>
                                                                        </div>
                                                                        {/* <Form.Check
                                                                            type="radio"
                                                                            style={{ display: this.state.questions[this.state.currentQuestion - 1].option4 == 'NA' ? 'none' : null }}
                                                                            onClick={() => this.handleAnswerOptionClick(this.state.questions[this.state.currentQuestion - 1].id, 4)}
                                                                            // label={"(D) " + this.state.questions[this.state.currentQuestion - 1].option4}
                                                                            label={<>(4) {(this.state.questions[this.state.currentQuestion - 1].option4_image ? <ModalImage small={this.state.questions[this.state.currentQuestion - 1].option4_image} large={this.state.questions[this.state.currentQuestion - 1].option4_image} /> : <div className="option-number-space" dangerouslySetInnerHTML={{ __html: this.state.questions[this.state.currentQuestion - 1].option4 }}></div>)}</>}
                                                                            name="formHorizontalRadios"
                                                                            id="formHorizontalRadios4"
                                                                            onChange={() => null}
                                                                            checked={this.state.questions[this.state.currentQuestion - 1].selectedOption === 4}
                                                                        /> */}
                                                                    </div>

                                                                    {this.state.showSolutions ?
                                                                        <div className="col-md-12 col-sm-12 col-xs-12 solution-images">
                                                                            <span>Solution: Correct Option: {(this.state.questions[this.state.currentQuestion - 1].answer_no)}
                                                                                <br />
                                                                                <div dangerouslySetInnerHTML={{ __html: this.state.questions[this.state.currentQuestion - 1].solution_text }}></div></span>
                                                                            {this.state.questions[this.state.currentQuestion - 1].answer_no ?
                                                                                // <img src={this.state.questions[this.state.currentQuestion - 1].solution_image}></img>
                                                                                <ModalImage small={this.state.questions[this.state.currentQuestion - 1].solution_image} large={this.state.questions[this.state.currentQuestion - 1].solution_image} />
                                                                                : null
                                                                            }
                                                                        </div>
                                                                        : null
                                                                    }
                                                                </div>
                                                            </Form>
                                                        </div>
                                                        <div className="pagination-main">
                                                            <p>(<span>{this.state.currentQuestion}</span>/<span>{this.state.questions.length}</span>)</p>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex justify-content-between">
                                                        <button className="btn btn-primary prev" onClick={this.prevStep}
                                                            disabled={this.state.currentQuestion > 1 ? null : "disabled"}
                                                        > <img src={PrevStepIcon} alt="Previous" /> Previous</button>
                                                        {/* <button className="btn btn-primary" onClick={this.calculateScore}>Submit</button>
                                <button className="btn btn-primary next" disabled={this.state.currentQuestion >= this.state.questions.length ? "disabled" : null} onClick={this.nextStep}>Next<img src={NextStepIcon} alt="Next" /></button>  */}

                                                        {this.state.currentQuestion >=
                                                            this.state.questions.length ? !this.state.showSolutions ? (
                                                                <button
                                                                    className="btn btn-primary"
                                                                    onClick={this.calculateScore}
                                                                >
                                                                    Submit Responses
                                                                </button>
                                                            ) : (
                                                            <Link to=".." className="btn btn-primary" >
                                                                Back to Home
                                                            </Link>
                                                        ) :
                                                            <button
                                                                className="btn btn-primary next"
                                                                onClick={this.nextStep}
                                                            >
                                                                Next
                                                                <img src={NextStepIcon} alt="Next" />
                                                            </button>
                                                        }

                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>


                                ) : null
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={this.state.isShareOpen} onHide={this.closeShareModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Share</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className="share-icon">
                            <ul>
                            <li> <Link onClick={() => this.handleCopied("Whatsapp")} to={{ pathname: "https://wa.me?text=" + this.state.copiedLink }} target="_blank" > <img src={PopupIcon1} alt="" /> <span>Whatsapp</span> </Link> </li>
                <li> <Link onClick={() => this.handleCopied("Facebook")} to={{ pathname: "https://www.facebook.com/sharer/sharer.php?u=" + this.state.copiedLink }} target="_blank" > <img src={PopupIcon2} alt="" /> <span>Facebook</span></Link> </li>
                <li> <Link onClick={() => this.handleCopied("Twitter")} to={{ pathname: "https://twitter.com/intent/tweet?url=" + this.state.copiedLink }} target="_blank" > <img src={PopupIcon4} alt="" /> <span>Twitter</span> </Link> </li>
                            </ul>
                        </div>
                        <div className="copied-link">
                            <Form.Group>
                                <Form.Control size="lg" type="text" placeholder="Large text" value={this.state.copiedLink} />
                                <Button variant="secondary" onClick={() => { toast('Link copied to clipboard'); this.closeModal; this.handleCopied("COPY"); }}>
                                    COPY
                                </Button>
                            </Form.Group>
                            {/* <Link to={{ pathname: this.state.copiedLink }} target="_blank" > {this.state.copiedLink} </Link> */}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.closeShareModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }
}


export default PracticeQA;



