import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import logo from './logo.svg';
import Header from './component/header';
import NotFound404 from './component/notFound404';
import Home from './Home';
import School from './School';
import MultipleQuestion from './MultipleQuestion';
import Videos from './Videos';
import PracticeQA from './PracticeQA';
import HomePage from './HomePage';
import PlayList from './PlayList';
import homeTest from './Home-page-test'

class App extends Component {
componentDidMount(){
    const disablePinchZoom = (e) => {
        if (e.touches.length > 1) {
          e.preventDefault()
        }
      }
      document.addEventListener("touchmove", disablePinchZoom, { passive: false })
}
    render() {
        return (
            <div className="main" >
                <Header />
                <div className="content">
                    <Switch>
                        <Route path="/" exact component={HomePage} />
                        <Route path="/:school" exact component={School} />
                        <Route path="/:school/content" exact component={Home} />
                        <Route path="/:school/content/p/:content_id/:qno?" exact component={PracticeQA} />
                        <Route path="/:school/content/v/:content_id" exact component={Videos} />
                        <Route path="/:school/content/pl/:content_id" exact component={PlayList} />
                        <Route path="/:school/test" exact component={homeTest} />
                        {/* <Route path="/404" component={NotFound404}/> */}
                    </Switch>
                </div>
            </div>
        );
    }
}

export default App;