import http from "./httpServices";

const getChaptersApiEndPoint = http.API_URL + "api/get-chapter-names";
const getTopicsApiEndPoint = http.API_URL + "api/get-topic-names";
const getClassApiEndpoint = http.API_URL + "api/get-grade-numbers";

function chaptersUrl() {
  return `${getChaptersApiEndPoint}/`;
}

function topicsUrl() {
  return `${getTopicsApiEndPoint}/`;
}

function classUrl() {
  return `${getClassApiEndpoint}/`;
}

export function getChapters(filter) {
  const videos = http.get(chaptersUrl(), {
    params: filter,
  });
  return videos;
}

export function getTopics(filter) {
  const practiceQues = http.get(topicsUrl(), {
    params: filter,
  });
  return practiceQues;
}

export function getClass(filter) {
  const classes = http.get(classUrl(), {
    params: filter,
  });
  return classes;
}
