import http from "./httpServices";

const sendMailApiEndPoint = http.API_URL + "api/send-mail";

function mailUrl() {
  return `${sendMailApiEndPoint}/`;
}

export function sendMail(data) {
    const response = http.post(mailUrl(), {
        name:data?.name,
        subject:data?.subject,
        email:data?.email,
        message:data?.message,

    });
    return response;
}