import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { getSchool } from "./services/schoolService";
import { getTopics, getChapters, getClass } from "./services/filterService";
import {
  getVideosContent,
  getPracticeQuesContent,
  getPlaylistContent,
} from "./services/contentService";
import {
  postShareAnalytics,
  postPracticeAnalytics,
} from "./services/analyticService";
import logo from "./logo.svg";
import CardImg1 from "./images/img-1.png";
import CardImg3 from "./images/img-3.png";
import CardImg4 from "./images/img-4.png";
import CardImg5 from "./images/img-5.png";
import Share from "./images/share-ic.png";
import Question from "./images/question-ic.png";
import VideoIcon from "./images/video-ic.png";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ToggleIcon from "./images/toggle.png";
import Modal from "react-bootstrap/Modal";
import PopupIcon1 from "./images/whatup-ic1.svg";
import PopupIcon2 from "./images/facebook.svg";
import PopupIcon4 from "./images/twitter (1).svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactGA from "react-ga";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Badge from "react-bootstrap/Badge";
import InfiniteScroll from "react-infinite-scroll-component";
import toast, { toastConfig } from "react-simple-toasts";
import Sidebar from "react-sidebar";
import CloseIcon from "./images/close-icon.png";
import { Helmet } from "react-helmet";
import reactReferer from "react-referer";
import SearchIcon from "./images/icons8-search.svg";
import CloseIconSearch from "./images/cross.svg";

const ytbThumbnail = require("youtube-img");

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // SchoolLogo: '',
      // SchoolName: '',
      school: [],
      videos: [],
      practiceQues: [],
      playlists: [],
      filter_language: [],
      filter_boards: [],
      filter_subject: [],
      filter_class_no: [],
      filter_chapter: [],
      filter_topic: [],
      selected_language: "English",
      selected_board: "",
      selected_search: "",
      selected_subject: "",
      selected_class_no: "",
      selected_chapter: "",
      selected_topic: "",
      copiedLink: "",
      isOpen: false,
      isValid: true,
      isLoading: true,
      currentVideoPage: 1,
      currentQAPage: 1,
      currentPLPage: 1,
      hasMoreVideos: true,
      hasMorePractice: true,
      playlists_count: 0,
      practiceQues_count: 0,
      videos_count: 0,
      hasMorePlaylist: true,
      sidebarOpen: false,
      subject_disabled: false,
      class_disabled: true,
      chapter_disabled: true,
      topic_disabled: true,
      scrollValue: 0,
      mobileSearchButton: false,
      mobileSearchText: undefined,
    };
    this.changeLanguage = this.changeLanguage.bind(this);
    this.changeBoards = this.changeBoards.bind(this);
    this.changeSubject = this.changeSubject.bind(this);
    this.changeClass = this.changeClass.bind(this);
    this.changeChapter = this.changeChapter.bind(this);
    this.changeTopic = this.changeTopic.bind(this);
    // this.changeSearch = this.changeSearch.bind(this);
    this.handleCopied = this.handleCopied.bind(this);
    this.scrollTop = this.scrollTop.bind(this);
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    toastConfig({
      time: 3000,
      className: "my-toast-message",
      position: "left",
    });

    //   ReactGA.ga((tracker) => {
    //     console.log(tracker.get('clientId'))
    //   });
  }
  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }
  scrollTop() {
    window.scrollTo({
      top: 0,
    });
  }
  reset = () => {
    this.formRef.current.reset();
  };

  fetchMoreVideoData = async () => {
    let currentVideoPage = this.state.currentVideoPage + 1;

    var params = {
      language: this.state.selected_language,
      subject: this.state.selected_subject,
      grade: this.state.selected_class_no,
      chapter_no: this.state.selected_chapter,
      video_topic_no: this.state.selected_topic,
      board: this.state.selected_board,
      page: currentVideoPage,
      search: this.state.selected_search,
    };

    // fetch video details
    await getVideosContent(params)
      .then((response) => {
        this.setState(() => ({
          videos: this.state.videos.concat(response.data.results),
        }));
        this.setState(() => ({ currentVideoPage: currentVideoPage }));

        // console.log(this.state);
      })
      .catch((error) => {
        console.log("Page Not Found");
        this.setState(() => ({ hasMoreVideos: false }));
        // return <Redirect to='/404'  />
        // this.setState({ isValid: false });
      });
  };

  fetchMorePracticeData = async () => {
    let currentQAPage = this.state.currentQAPage + 1;
    var params = {
      language: this.state.selected_language,
      subject: this.state.selected_subject,
      grade: this.state.selected_class_no,
      chapter_no: this.state.selected_chapter,
      video_topic_no: this.state.selected_topic,
      board: this.state.selected_board,
      page: currentQAPage,
      search: this.state.selected_search,
    };
    // fetch video details
    await getPracticeQuesContent(params)
      .then((response) => {
        this.setState(() => ({
          practiceQues: this.state.practiceQues.concat(response.data?.results),
        }));
        this.setState(() => ({ currentQAPage: currentQAPage }));
        // console.log(this.state);
      })
      .catch((error) => {
        console.log("Page Not Found");
        this.setState(() => ({ hasMorePractice: false }));
        // return <Redirect to='/404'  />
        // this.setState({ isValid: false });
      });
  };

  fetchMorePlaylistData = async () => {
    let currentPLPage = this.state.currentPLPage + 1;
    var params = {
      language: this.state.selected_language,
      subject: this.state.selected_subject,
      grade: this.state.selected_class_no,
      chapter_no: this.state.selected_chapter,
      video_topic_no: this.state.selected_topic,
      board: this.state.selected_board,
      page: currentPLPage,
      search: this.state.selected_search,
      mobileSearchText: this.state.selected_search,
    };
    // fetch video details
    await getPlaylistContent(params)
      .then((response) => {
        this.setState(() => ({
          playlists: this.state.playlists.concat(response.data.results),
        }));
        this.setState(() => ({ currentPLPage: currentPLPage }));
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        // console.log("Page Not Found");
        this.setState(() => ({ hasMorePlaylist: false }));
        // return <Redirect to='/404'  />
        // this.setState({ isValid: false });
      });
  };

  handleScroll = () => {
    const position = window.pageYOffset;
    // setScrollPosition(position);
    console.log("document.referrer ------->", this.state.scrollValue);
    this.setState({ scrollValue: position });
    //
  };

  async componentDidMount() {
    // const listSearch = localStorage.setItem("lastSearch", "filter");
    // const lastSearch =  localStorage.setItem("lastSearch", "search");
    if (localStorage.getItem("lastSearch") == "filter") {
      //clear searchBar
      this.setState({ selected_search: "" });
      localStorage.setItem("tic_tac_selected_search", "");
    } else {
      //clear filter
      this.setState({ selected_language: "" });
      this.setState({ selected_subject: "" });
      this.setState({ selected_class_no: "" });
      this.setState({ selected_chapter: "" });
      this.setState({ selected_board: "" });
      this.setState({ selected_topic: "" });
      localStorage.setItem("tic_tac_selected_language", "");
      localStorage.setItem("tic_tac_selected_subject", "");
      localStorage.setItem("tic_tac_selected_class_no", "");
      localStorage.setItem("tic_tac_selected_chapter", "");
      localStorage.setItem("tic_tac_selected_board", "");
      localStorage.setItem("tic_tac_selected_topic", "");
    }

    window.addEventListener("resize", this.resize());
    window.addEventListener("beforeunload", function (e) {
      if (event.currentTarget.performance.navigation.type === 1) {
        localStorage.clear();
      }
    });
    window.addEventListener("scroll", this.handleScroll);
    if (this.props.history.action.toString() === "POP") {
      localStorage.clear();
    }
    const disablePinchZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };
    document.addEventListener("touchmove", disablePinchZoom, {
      passive: false,
    });
    this.scrollTop();
    ReactGA.set({ dimension1: this.props.match.params.school });
    ReactGA.set({ dimension2: "Content" });
    ReactGA.event({
      category: "Content",
      action: "Content Visited",
    });
    ReactGA.pageview(window.location.pathname + window.location.search);

    const filter_language = [
      {
        label: "Hindi",
        value: "Hindi",
        default: localStorage.getItem("tic_tac_selected_language") == "Hindi",
      },
      {
        label: "English",
        value: "English",
        default: localStorage.getItem("tic_tac_selected_language") == "English",
      },
      {
        label: "Marathi",
        value: "Marathi",
        default: localStorage.getItem("tic_tac_selected_language") == "Marathi",
      },
      {
        label: "Telugu",
        value: "Telugu",
        default: localStorage.getItem("tic_tac_selected_language") == "Telugu",
      },
      {
        label: "Odia",
        value: "Odia",
        default: localStorage.getItem("tic_tac_selected_language") == "Odia",
      },
      {
        label: "Gujarati",
        value: "Gujarati",
        default:
          localStorage.getItem("tic_tac_selected_language") == "Gujarati",
      },
      {
        label: "Tamil (Coming Soon...)",
        value: "Tamil ",
        default: localStorage.getItem("tic_tac_selected_language") == "Tamil",
      },
      {
        label: "Punjabi (Coming Soon...)",
        value: "Punjabi ",
        default: localStorage.getItem("tic_tac_selected_language") == "Punjabi",
      },
    ];

    const filter_boards = [
      {
        label: "CBSE",
        value: "CBSE",
        default: localStorage.getItem("tic_tac_selected_board") == "CBSE",
      },
      {
        label: "GSEB",
        value: "GSEB",
        default: localStorage.getItem("tic_tac_selected_board") == "GSEB",
      },
      {
        label: "MSEB",
        value: "MSEB",
        default: localStorage.getItem("tic_tac_selected_board") == "MSEB",
      },
    ];

    const filter_subject = [
      {
        label: "Mathematics",
        value: "Maths",
        default: localStorage.getItem("tic_tac_selected_subject") == "Maths",
      },
      {
        label: "Science",
        value: "Science",
        default: localStorage.getItem("tic_tac_selected_subject") == "Science",
      },
    ];

    const filter_class_no = [
      //   {
      //     label: "1st",
      //     value: "1",
      //     default: localStorage.getItem("tic_tac_selected_class_no") == "1",
      //   },
      //   {
      //     label: "2nd",
      //     value: "2",
      //     default: localStorage.getItem("tic_tac_selected_class_no") == "2",
      //   },
      //   {
      //     label: "3rd",
      //     value: "3",
      //     default: localStorage.getItem("tic_tac_selected_class_no") == "3",
      //   },
      //   {
      //     label: "4th",
      //     value: "4",
      //     default: localStorage.getItem("tic_tac_selected_class_no") == "4",
      //   },
      //   {
      //     label: "5th",
      //     value: "5",
      //     default: localStorage.getItem("tic_tac_selected_class_no") == "5",
      //   },
      //   {
      //     label: "6th",
      //     value: "6",
      //     default: localStorage.getItem("tic_tac_selected_class_no") == "6",
      //   },
      //   {
      //     label: "7th",
      //     value: "7",
      //     default: localStorage.getItem("tic_tac_selected_class_no") == "7",
      //   },
      //   {
      //     label: "8th",
      //     value: "8",
      //     default: localStorage.getItem("tic_tac_selected_class_no") == "8",
      //   },
      //   {
      //     label: "9th",
      //     value: "9",
      //     default: localStorage.getItem("tic_tac_selected_class_no") == "9",
      //   },
      //   {
      //     label: "10th",
      //     value: "10",
      //     default: localStorage.getItem("tic_tac_selected_class_no") == "10",
      //   },
    ];

    const filter_chapter = [
      // {'label': 'Chapter 1 - ', 'value': '1'},
      // {'label': 'Chapter 2 - ', 'value': '2'},
      // {'label': 'Chapter 3 - ', 'value': '3'},
      // {'label': 'Chapter 4 - ', 'value': '4'},
      // {'label': 'Chapter 5 - ', 'value': '5'},
      // {'label': 'Chapter 6 - ', 'value': '6'},
      // {'label': 'Chapter 7 - ', 'value': '7'},
      // {'label': 'Chapter 8 - ', 'value': '8'},
      // {'label': 'Chapter 9 - ', 'value': '9'},
      // {'label': 'Chapter 10 - ', 'value': '10'},
    ];

    const filter_topic = [
      // {'label': 'Topic 1 - ', 'value': '1'},
      // {'label': 'Topic 2 - ', 'value': '2'},
      // {'label': 'Topic 3 - ', 'value': '3'},
      // {'label': 'Topic 4 - ', 'value': '4'},
      // {'label': 'Topic 5 - ', 'value': '5'},
      // {'label': 'Topic 6 - ', 'value': '6'},
      // {'label': 'Topic 7 - ', 'value': '7'},
      // {'label': 'Topic 8 - ', 'value': '8'},
      // {'label': 'Topic 9 - ', 'value': '9'},
      // {'label': 'Topic 10 - ', 'value': '10'},
    ];

    this.setState(() => ({
      filter_language: filter_language,
      filter_subject: filter_subject,
      filter_class_no: filter_class_no,
      filter_chapter: filter_chapter,
      filter_topic: filter_topic,
      filter_boards: filter_boards,
    }));

    {
      localStorage.getItem("tic_tac_selected_language") === "null" &&
        this.setState({ subject_disabled: true });
    }

    var filter = {
      language:
        localStorage.getItem("tic_tac_selected_language") === "null"
          ? ""
          : localStorage.getItem("tic_tac_selected_language") || "English",
      board:
        localStorage.getItem("tic_tac_selected_board") === "null"
          ? ""
          : localStorage.getItem("tic_tac_selected_board") || "",
      subject: localStorage.getItem("tic_tac_selected_subject") || "",
      grade: localStorage.getItem("tic_tac_selected_class_no") || "",
      chapter_no: localStorage.getItem("tic_tac_selected_chapter") || "",
      video_topic_no: localStorage.getItem("tic_tac_selected_topic") || "",
    };

    await this.setState({
      selected_language:
        localStorage.getItem("tic_tac_selected_language") || "",
      selected_subject: localStorage.getItem("tic_tac_selected_subject"),
      selected_class_no: localStorage.getItem("tic_tac_selected_class_no"),
      selected_chapter: localStorage.getItem("tic_tac_selected_chapter"),
      selected_topic: localStorage.getItem("tic_tac_selected_topic"),
      selected_search: localStorage.getItem("tic_tac_selected_search"),
    });

    this.setState({ isLoading: true });
    if (localStorage.getItem("lastSearch") === "filter") {
      // fetch school details
      await getSchool(this.props.match.params.school)
        .then((response) => {
          this.setState(() => ({ school: response.data }));
        })
        .catch((error) => {
          console.log("Page Not Found");
          // return <Redirect to='/404'  />
          this.setState({ isValid: false });
        });

      // fetch video details
      await getVideosContent(filter)
        .then((response) => {
          this.setState(() => ({ videos: response.data.results }));
          this.setState(() => ({ videos_count: response.data.count }));
          // console.log(response);
        })
        .catch((error) => {
          console.log("Page Not Found");
          // return <Redirect to='/404'  />
          // this.setState({ isValid: false });
        });

      // fetch question details
      await getPracticeQuesContent(filter)
        .then((response) => {
          this.setState(() => ({ practiceQues: response.data.results }));
          this.setState(() => ({ practiceQues_count: response.data.count }));
          // console.log(response);
        })
        .catch((error) => {
          console.log("Page Not Found");
          // return <Redirect to='/404'  />
          // this.setState({ isValid: false });
        });

      // fetch playlist details
      await getPlaylistContent(filter)
        .then((response) => {
          this.setState(() => ({ playlists: response.data.results }));
          this.setState(() => ({ playlists_count: response.data.count }));
          this.setState({ isLoading: false });
          // console.log(response);
        })
        .catch((error) => {
          console.log("Page Not Found");
          this.setState(() => ({ playlists: [] }));

          // return <Redirect to='/404'  />
          // this.setState({ isValid: false });
        });

      // const { data: schoolObj } = await getSchool(this.props.match.params.slug);
      // console.log(schoolObj);

      // if(! schoolObj.id){
      //     console.log("Page Not Found");
      // }
      // console.log(this.props.match.params.slug);

      // const schoolLogo = '../images/ttl-logo2.png';
      // const schoolName = 'Maharishi Vidyamandir';
      // this.setState({ SchoolLogo: schoolObj.logo, SchoolName: schoolObj.name, cardDetail: cardDetail, })

      if (this.state.selected_subject) {
        this.setState({ subject_disabled: false });
        var filter = {
          language: this.state.selected_language,
          grade: this.state.selected_class_no,
          subject: this.state.selected_subject,
          board: this.state.selected_board,
          chapter_no: this.state.selected_chapter,
        };
        getClass(filter)
          .then((response) => {
            this.setState(() => ({ filter_class_no: response.data }));
          })
          .catch((error) => {
            console.log("Network Error");
          });
        this.setState({ chapter_disabled: false });
      }
      if (this.state.selected_class_no) {
        this.setState({ class_disabled: false });
        var filter = {
          language: this.state.selected_language,
          grade: this.state.selected_class_no,
          subject: this.state.selected_subject,
          board: this.state.selected_board,
          chapter_no: this.state.selected_chapter,
        };
        getChapters(filter)
          .then((response) => {
            this.setState(() => ({ filter_chapter: response.data }));
          })
          .catch((error) => {
            console.log("Network Error");
            // this.setState({ isValid: false });
          });
        this.setState({ chapter_disabled: false });
      }
      if (this.state.selected_chapter) {
        this.setState({ chapter_disabled: false });
        var filter = {
          language: this.state.selected_language,
          subject: this.state.selected_subject,
          grade: this.state.selected_class_no,
          chapter_no: this.state.selected_chapter,
          board: this.state.selected_board,
        };
        getTopics(filter)
          .then((response) => {
            this.setState(() => ({ filter_topic: response.data }));
            // console.log(response);
          })
          .catch((error) => {
            console.log("Network Error");
            // this.setState({ isValid: false });
          });
        this.setState({ topic_disabled: false });
      }
    } else {
      await getSchool(this.props.match.params.school)
        .then((response) => {
          this.setState(() => ({ school: response.data }));
          console.log(response);
        })
        .catch((error) => {
          console.log("Page Not Found");
          // return <Redirect to='/404'  />
          this.setState({ isValid: false });
        });
      const filter = {};
      filter["search"] = this.state.selected_search;
      getVideosContent(filter)
        .then((response) => {
          this.setState(() => ({ videos: [] }));
          this.setState(() => ({ videos: response.data.results }));
          this.setState(() => ({ videos_count: response.data.count }));
          // console.log(response);
        })
        .catch((error) => {
          console.log("Network Error");
          this.setState(() => ({ videos: [] }));
          // return <Redirect to='/404'  />
          // this.setState({ isValid: false });
        });
      getPracticeQuesContent(filter)
        .then((response) => {
          this.setState(() => ({ practiceQues: [] }));
          this.setState(() => ({ practiceQues: response.data.results }));
          this.setState(() => ({ practiceQues_count: response.data.count }));
          // console.log(response);
        })
        .catch((error) => {
          console.log("Network Error");
          this.setState(() => ({ practiceQues: [] }));
          // return <Redirect to='/404'  />
          // this.setState({ isValid: false });
        });

      // fetch playlist details
      getPlaylistContent(filter)
        .then((response) => {
          this.setState(() => ({ playlists: [] }));
          this.setState(() => ({ playlists: response.data.results }));
          this.setState(() => ({ playlists_count: response.data.count }));
          this.setState({ isLoading: false });
          // console.log(response);
        })
        .catch((error) => {
          console.log("Network Error");
          this.setState(() => ({ s: [] }));
          this.setState(() => ({ practiceQues_count: 0 }));
          // return <Redirect to='/404'  />
          // this.setState({ isValid: false });
        });
    }
  }

  resize() {
    console.log("----------------->", window.innerWidth <= 760);
  }
  changeBoards(event) {
    this.setState({ selected_board: event.target.value });
    localStorage.setItem(
      "tic_tac_selected_board",
      event.target.value == "" ? null : event.target.value
    );
  }

  changeLanguage(event) {
    this.setState({ selected_language: event.target.value });
    localStorage.setItem(
      "tic_tac_selected_language",
      event.target.value == "" ? null : event.target.value
    );

    if (event.target.value) {
      this.setState({ subject_disabled: false });
    } else {
      this.setState({ subject_disabled: true });
      this.setState({ class_disabled: true });
      this.setState({ chapter_disabled: true });
      this.setState({ topic_disabled: true });
    }

    if (
      !this.state.selected_language &&
      !this.state.selected_subject &&
      !this.state.selected_class_no
    ) {
      return;
    }

    var filter = {
      language: event.target.value,
      subject: this.state.selected_subject,
      grade: this.state.selected_class_no,
      board: this.state.selected_board,
      chapter_no: this.state.selected_chapter,
    };

    // fetch class details
    getClass(filter)
      .then((response) => {
        this.setState(() => ({ filter_class_no: response.data }));
      })
      .catch((error) => {
        console.log("Network Error");
        // this.setState({ isValid: false });
      });
    // fetch chapter details
    getChapters(filter)
      .then((response) => {
        this.setState(() => ({ filter_chapter: response.data }));
      })
      .catch((error) => {
        console.log("Network Error");
        // this.setState({ isValid: false });
      });

    // fetch topic details
    getTopics(filter)
      .then((response) => {
        this.setState(() => ({ filter_topic: response.data }));
      })
      .catch((error) => {
        console.log("Network Error");
        // this.setState({ isValid: false });
      });

    this.setState({ filter_chapter: this.state.filter_chapter });
    this.setState({ filter_topic: this.state.filter_topic });
    // console.log(filter);
  }

  async changeSubject(event) {
    localStorage.setItem("tic_tac_selected_subject", event.target.value);

    this.setState({ selected_subject: event.target.value });
    if (event.target.value) {
      this.setState({ class_disabled: false });
    } else {
      this.setState({ class_disabled: true });
      this.setState({ chapter_disabled: true });
      this.setState({ topic_disabled: true });
    }

    if (
      !this.state.selected_language &&
      !this.state.selected_subject &&
      !this.state.selected_class_no
    ) {
      return;
    }

    var filter = {
      language: this.state.selected_language,
      subject: event.target.value,
      grade: this.state.selected_class_no,
      board: this.state.selected_board,
      chapter_no: this.state.selected_chapter,
    };

    // fetch class details
    getClass(filter)
      .then((response) => {
        this.setState(() => ({ filter_class_no: response.data }));
      })
      .catch((error) => {
        console.log("Network Error");
        // this.setState({ isValid: false });
      });

    // fetch chapter details
    getChapters(filter)
      .then((response) => {
        this.setState(() => ({ filter_chapter: response.data }));
      })
      .catch((error) => {
        console.log("Network Error");
        // this.setState({ isValid: false });
      });

    // fetch topic details
    getTopics(filter)
      .then((response) => {
        this.setState(() => ({ filter_topic: response.data }));
      })
      .catch((error) => {
        console.log("Network Error");
        // this.setState({ isValid: false });
      });

    this.setState({ filter_chapter: this.state.filter_chapter });
    this.setState({ filter_topic: this.state.filter_topic });
  }

  changeClass(event) {
    this.setState({ selected_class_no: event.target.value });
    localStorage.setItem("tic_tac_selected_class_no", event.target.value);
    if (event.target.value) {
      this.setState({ chapter_disabled: false });
    } else {
      this.setState({ chapter_disabled: true });
      this.setState({ topic_disabled: true });
    }

    var filter = {
      language: this.state.selected_language,
      subject: this.state.selected_subject,
      grade: event.target.value,
      board: this.state.selected_board,
      chapter_no: this.state.selected_chapter,
    };
    // fetch chapter details
    getChapters(filter)
      .then((response) => {
        this.setState(() => ({ filter_chapter: response.data }));
        // console.log(response);
      })
      .catch((error) => {
        console.log("Network Error");
        // this.setState({ isValid: false });
      });

    // fetch topic details
    getTopics(filter)
      .then((response) => {
        this.setState(() => ({ filter_topic: response.data }));
        // console.log(response);
      })
      .catch((error) => {
        console.log("Network Error");
        // this.setState({ isValid: false });
      });

    this.setState({ filter_chapter: this.state.filter_chapter });
    this.setState({ filter_topic: this.state.filter_topic });
    // console.log(filter);
  }

  changeChapter(event) {
    this.setState({ selected_chapter: event.target.value });
    localStorage.setItem("tic_tac_selected_chapter", event.target.value);
    if (event.target.value) {
      this.setState({ topic_disabled: false });
    } else {
      this.setState({ topic_disabled: true });
    }

    var filter = {
      language: this.state.selected_language,
      subject: this.state.selected_subject,
      grade: this.state.selected_class_no,
      chapter_no: event.target.value,
      board: this.state.selected_board,
    };

    // fetch topic details
    getTopics(filter)
      .then((response) => {
        this.setState(() => ({ filter_topic: response.data }));
        // console.log(response);
      })
      .catch((error) => {
        console.log("Network Error");
        // this.setState({ isValid: false });
      });

    this.setState({ filter_chapter: this.state.filter_chapter });
    this.setState({ filter_topic: this.state.filter_topic });
    // console.log(filter);
  }

  changeTopic(event) {
    this.setState({ selected_topic: event.target.value });
    localStorage.setItem("tic_tac_selected_topic", event.target.value);
    // const stats = this.state.countries.find(cntry => cntry.name === this.state.selectedCountry).states;
    // this.setState({cities : stats.find(stat => stat.name === event.target.value).cities});
    // console.log(event.target.value);
  }

  handleSearchSubmit = (event) => {
    // const formdata = new FormData(event.target)
    const filter = {};
    localStorage.setItem("lastSearch", "search");
    localStorage.setItem("tic_tac_selected_search", this.state.selected_search);
    filter["search"] = this.state.selected_search;
    this.setState({ selected_language: "" });
    this.setState({ selected_subject: "" });
    this.setState({ selected_class_no: "" });
    this.setState({ selected_chapter: "" });
    this.setState({ selected_board: "" });
    this.setState({ selected_topic: "" });
    // filter["language"] = this.state.selected_language;
    // filter["subject"] = this.state.selected_subject;
    // filter["grade"] = this.state.selected_class_no;
    // filter["chapter_no"] = this.state.selected_chapter;
    // filter["board"] = this.state.selected_board;
    getVideosContent(filter)
      .then((response) => {
        this.setState(() => ({ videos: [] }));
        this.setState(() => ({ videos: response.data.results }));
        this.setState(() => ({ videos_count: response.data.count }));
        // console.log(response);
      })
      .catch((error) => {
        console.log("Network Error");
        this.setState(() => ({ videos: [] }));
        // return <Redirect to='/404'  />
        // this.setState({ isValid: false });
      });
    getPracticeQuesContent(filter)
      .then((response) => {
        this.setState(() => ({ practiceQues: [] }));
        this.setState(() => ({ practiceQues: response.data.results }));
        this.setState(() => ({ practiceQues_count: response.data.count }));
        this.setState({ isLoading: false });
        // console.log(response);
      })
      .catch((error) => {
        console.log("Network Error");
        this.setState(() => ({ practiceQues: [] }));
        // return <Redirect to='/404'  />
        // this.setState({ isValid: false });
      });

    // fetch playlist details
    getPlaylistContent(filter)
      .then((response) => {
        this.setState(() => ({ playlists: [] }));
        this.setState(() => ({ playlists: response.data.results }));
        this.setState(() => ({ playlists_count: response.data.count }));
        this.setState({ isLoading: false });
        // console.log(response);
      })
      .catch((error) => {
        console.log("Network Error");
        this.setState(() => ({ playlists: [] }));
        this.setState(() => ({ practiceQues_count: 0 }));
        // return <Redirect to='/404'  />
        // this.setState({ isValid: false });
      });
    event.preventDefault();
  };

  handleMobileSearchSubmit = (event) => {
    const filter = {};
    localStorage.setItem("lastSearch", "search");
    // localStorage.setItem("lastSearch", "filter");
    // // localStorage.setItem("tic_tac_selected_search", this.state.selected_search);
    filter["search"] = this.state.selected_search;
    localStorage.setItem("tic_tac_selected_search", this.state.selected_search);
    // console.log( this.state.selected_search);

    // filter["search"] = this.state.mobileSearchText;
    // filter["search"] = this.state.selected_search;
    // this.setState({selected_search: ""});
    this.setState({ selected_language: "" });
    this.setState({ selected_subject: "" });
    this.setState({ selected_class_no: "" });
    this.setState({ selected_chapter: "" });
    this.setState({ selected_board: "" });
    this.setState({ selected_topic: "" });
    // filter["language"] = this.state.selected_language;
    // filter["subject"] = this.state.selected_subject;
    // filter["grade"] = this.state.selected_class_no;
    // filter["chapter_no"] = this.state.selected_chapter;
    // filter["board"] = this.state.selected_board;
    getVideosContent(filter)
      .then((response) => {
        this.setState(() => ({ videos: [] }));
        this.setState(() => ({ videos: response.data.results }));
        this.setState(() => ({ videos_count: response.data.count }));
        // this.setState(() => ({ hasMoreVideos: false }));
        // console.log(response);
      })
      .catch((error) => {
        console.log("Network Error");
        this.setState(() => ({ videos: [] }));
        // this.setState(() => ({ hasMorePlaylist: false }));
        // return <Redirect to='/404'  />
        // this.setState({ isValid: false });
      });
    getPracticeQuesContent(filter)
      .then((response) => {
        this.setState(() => ({ practiceQues: [] }));
        this.setState(() => ({ practiceQues: response.data.results }));
        this.setState(() => ({ practiceQues_count: response.data.count }));
        // this.setState(() => ({ hasMorePractice: false }));
        // console.log(response);
      })
      .catch((error) => {
        console.log("Network Error");
        this.setState(() => ({ practiceQues: [] }));
        // this.setState(() => ({ hasMorePlaylist: false }));
        // return <Redirect to='/404'  />
        // this.setState({ isValid: false });
      });

    // fetch playlist details
    getPlaylistContent(filter)
      .then((response) => {
        this.setState(() => ({ playlists: [] }));
        this.setState(() => ({ playlists: response.data.results }));
        this.setState(() => ({ playlists_count: response.data.count }));
        // this.setState(() => ({ hasMorePlaylist: false }));
        // console.log(response);
      })
      .catch((error) => {
        console.log("Network Error");
        this.setState(() => ({ playlists: [] }));
        this.setState(() => ({ practiceQues_count: 0 }));

        // return <Redirect to='/404'  />
        // this.setState({ isValid: false });
      });
    this.setState({ mobileSearchButton: false });
    event.preventDefault();
  };

  handleSubmit = (event) => {
    this.setState({ isLoading: true });
    this.setState({ selected_search: "" });
    const formdata = new FormData(event.target);
    localStorage.setItem("lastSearch", "filter");
    // console.log("formData -----##>", localStorage.getItem("lastSearch"));
    console.log("formData ----->", formdata);
    const filter = {};
    formdata.forEach(function (value, prop) {
      filter[prop] = value;
    });
    // filter["search"] = this.state.selected_search
    // console.log(filter);
    console.log("filter ------##>", filter);

    // fetch video details
    getVideosContent(filter)
      .then((response) => {
        this.setState(() => ({ videos: [] }));
        this.setState(() => ({ videos: response.data.results }));
        this.setState(() => ({ videos_count: response.data.count }));
        // console.log(response);
      })
      .catch((error) => {
        console.log("Network Error");
        this.setState(() => ({ videos: [] }));
        // return <Redirect to='/404'  />
        // this.setState({ isValid: false });
      });

    // fetch question details
    getPracticeQuesContent(filter)
      .then((response) => {
        this.setState(() => ({ practiceQues: [] }));
        this.setState(() => ({ practiceQues: response.data.results }));
        this.setState(() => ({ practiceQues_count: response.data.count }));
        // console.log(response);
      })
      .catch((error) => {
        console.log("Network Error");
        this.setState(() => ({ practiceQues: [] }));
        // return <Redirect to='/404'  />
        // this.setState({ isValid: false });
      });

    // fetch playlist details
    getPlaylistContent(filter)
      .then((response) => {
        this.setState(() => ({ playlists: [] }));
        this.setState(() => ({ playlists: response.data.results }));
        this.setState(() => ({ playlists_count: response.data.count }));
        // this.setState({"search":false})
        // console.log('///////////',response);
      })
      .catch((error) => {
        console.log("Network Error");
        this.setState(() => ({ playlists: [] }));
        this.setState(() => ({ practiceQues_count: 0 }));
        // return <Redirect to='/404'  />
        // this.setState({ isValid: false });
      });

    // reset page count states
    this.setState(() => ({
      currentVideoPage: 1,
      currentQAPage: 1,
      currentPLPage: 1,
    }));
    this.setState(() => ({
      hasMoreVideos: true,
      hasMorePractice: true,
      hasMorePlaylist: true,
    }));

    this.setState({ isLoading: false });
    event.preventDefault();
  };

  handleCopied(type) {
    // send to Google Analytics
    console.log("type ---->", type);
    ReactGA.set({ dimension4: this.state.copiedLink });
    ReactGA.event({
      category: "Link Copied",
      action: "Content Link Copied",
      label: "Content Link Copied",
      value: this.state.copiedLink,
    });

    let data = {
      url: this.state.copiedLink,
      slug: this.state.copiedSlug,
      content_type: this.state.copiedType,
      school_name: this.props.match.params.school,
      type: type.toLowerCase(),
    };
    // console.log(this.state);

    postShareAnalytics(data)
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        console.log("Network Error");
        // return <Redirect to='/404'  />
        // this.setState({ isValid: false });
      });
    console.log(this.state);
  }
  // AddClassToggle = (event) => {
  //     if (this.state.sidebarOpen)
  //      document.body.classList.add('modal-open');
  //     else
  //     document.body.classList.remove('modal-open');
  // }
  // AddClassToggle = event => { this.state.sidebarOpen ? document.body.classList.add('modal-open') : document.body.classList.remove('modal-open') };
  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });
  render() {
    if (!this.state.isValid) return <Redirect to=".." />;
    // return <Redirect to='/404'  />
    return (
      <div className="home-page-main">
        <Helmet>
          <title>{"TicTacLearn " + this.state.school.name + " Content"}</title>
          <meta
            name="description"
            content={`TicTacLearn ${this.state.school.name} Content`}
          />
        </Helmet>
        <div className="container">
          <div className="sidebar-for-mobile">
            <Sidebar
              open={this.state.sidebarOpen}
              onSetOpen={this.onSetSidebarOpen}
              styles={{ sidebar: { background: "white" } }}
              contentClassName="toggle-btn"
              sidebarClassName="logo-sidebar"
              overlayClassName="overlay-bg"
              sidebar={
                <div className="col-sm-12 col-md-4 col-xs-12">
                  <div className="sidebar-main1">
                    <div className="sidebar-main">
                      <div className="close-sidebar">
                        <button
                          className="sidebar-close"
                          onClick={() => this.onSetSidebarOpen(false)}
                        >
                          {" "}
                          <img src={CloseIcon} alt="Close" />{" "}
                        </button>
                      </div>
                    </div>
                    {/* <div>
                      <Form
                        className="filter-form sidebar-new-search-field"
                        ref="form1"
                        onSubmit={this.handleSearchSubmit}
                      >
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Control
                            type="input"
                            placeholder="Enter the search textttttttttttttt"
                            onChange={(event) =>
                              this.setState({
                                selected_search: event.target.value,
                              })
                            }
                          />
                        </Form.Group>
                        <div className="submit-btn">
                          <Button type="submit" id="submit" className="mb-2">
                            Search
                          </Button>
                        </div>
                      </Form>
                    </div> */}
                    <div className="home-top-details-select">
                      <h2>Select Content</h2>
                      <Form
                        className="filter-form"
                        ref="form"
                        onSubmit={this.handleSubmit}
                      >
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Label>Language</Form.Label>
                          <Form.Control
                            as="select"
                            name="language"
                            value={this.state.selected_language}
                            onChange={this.changeLanguage}
                          >
                            <option value="">Select Language</option>
                            {this.state.filter_language.map((item, index) => (
                              <option
                                value={item.value}
                                key={index}
                                selected={item.default ? true : false}
                                default={item.default}
                              >
                                {item.label}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Label>Boards</Form.Label>
                          <Form.Control
                            as="select"
                            value={this.state.selected_board}
                            name="board"
                            onChange={this.changeBoards}
                          >
                            <option value="">Select Boards</option>
                            {this.state.filter_boards.map((item, index) => (
                              <option
                                value={item.value}
                                key={index}
                                selected={item.default ? true : false}
                                default={item.default}
                              >
                                {item.label}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Label>Subject</Form.Label>
                          <Form.Control
                            as="select"
                            value={this.state.selected_subject}
                            name="subject"
                            disabled={this.state.subject_disabled}
                            onChange={this.changeSubject}
                          >
                            <option value="">Select Subject</option>
                            {!this.state.subject_disabled &&
                              this.state.filter_subject.map((item, index) => (
                                <option
                                  value={item.value}
                                  key={index}
                                  selected={item.default ? true : false}
                                  default={item.default}
                                >
                                  {item.label}
                                </option>
                              ))}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Label>Class</Form.Label>
                          <Form.Control
                            as="select"
                            name="grade"
                            value={this.state.selected_class_no}
                            disabled={this.state.class_disabled}
                            onChange={this.changeClass}
                          >
                            <option value="">Select Class</option>
                            {!this.state.class_disabled &&
                              this.state.filter_class_no.map((item, index) => {
                                return (
                                  <option
                                    value={item.grade}
                                    key={index}
                                    selected={
                                      item?.grade ===
                                      this?.state?.selected_class_no?.toString()
                                    }
                                  >
                                    {item.grade}
                                  </option>
                                );
                              })}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Label>Chapter</Form.Label>
                          <Form.Control
                            as="select"
                            value={this.state.chapter}
                            name="chapter_no"
                            disabled={this.state.chapter_disabled}
                            onChange={this.changeChapter}
                          >
                            <option value="">Select Chapter</option>
                            {!this.state.chapter_disabled &&
                              this.state.filter_chapter.map((item, index) => (
                                <option
                                  value={item.chapter_no}
                                  key={index}
                                  selected={
                                    item?.chapter_no ===
                                    this?.state?.selected_chapter?.toString()
                                  }
                                >
                                  {item.chapter_no} -{" "}
                                  {item.chapter_name_original}
                                </option>
                              ))}
                          </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="exampleForm.ControlSelect1">
                          <Form.Label>Topic</Form.Label>
                          <Form.Control
                            as="select"
                            value={this.state.topic}
                            name="video_topic_no"
                            disabled={this.state.topic_disabled}
                            onChange={this.changeTopic}
                          >
                            <option value="">Select Topic</option>
                            {!this.state.topic_disabled &&
                              this.state.filter_topic.map((item, index) => (
                                <option
                                  value={item.video_topic_no}
                                  key={index}
                                  selected={
                                    item?.video_topic_no ===
                                    this?.state?.selected_topic?.toString()
                                  }
                                >
                                  {item.video_topic_no} -{" "}
                                  {item.video_topic_name}
                                </option>
                              ))}
                          </Form.Control>
                        </Form.Group>
                        <div className="submit-btn">
                          <Button
                            type="reset"
                            onClick={() => {
                              localStorage.clear();
                              window.location.reload(false);
                            }}
                            className="mb-2"
                          >
                            Reset
                          </Button>
                          <Button
                            onClick={() => {
                              this.onSetSidebarOpen(false);
                            }}
                            type="submit"
                            id="submit"
                            className="mb-2"
                          >
                            Search
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              }
            >
              <button
                className="mobile-toggle-btn"
                onClick={() => this.onSetSidebarOpen(true)}
              >
                <img onClick={this.AddClassToggle} src={ToggleIcon} />
              </button>
            </Sidebar>
          </div>
          {/* {this.state.isLoading ? <LoadingSpinner /> : <ResultsTable results={data} />} */}
          <div className="row">
            <div className="col-sm-12 col-md-4 col-xs-12">
              <div className="sidebar-main1 hide-desktop">
                <div className="sidebar-main">
                  <div className="school-logo-box">
                    {/* <img src={SchoolLogo} alt="" /> */}
                    <Link to={`/${this.state.school.slug_url}`}>
                      <img src={this.state.school.logo} alt="" />
                      <p>{this.state.school.name}</p>
                    </Link>
                  </div>
                </div>
                <div>
                  {/* <Form
                    className="filter-form sidebar-new-search-field"
                    ref="form1"
                    onSubmit={this.handleSearchSubmit}
                  >
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Control
                        type="input"
                        placeholder="Enter the search text"
                        onChange={(event) =>
                          this.setState({ selected_search: event.target.value })
                        }
                      />
                    </Form.Group>
                    <div className="submit-btn">
                      <Button type="submit" id="submit" className="mb-2">
                        Search
                      </Button>
                    </div>
                  </Form> */}
                </div>
                <div className="home-top-details-select">
                  <h2>Select Content</h2>
                  <Form
                    className="filter-form"
                    ref="form"
                    onSubmit={this.handleSubmit}
                  >
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>Language</Form.Label>
                      <Form.Control
                        value={this.state.selected_language}
                        as="select"
                        name="language"
                        onChange={this.changeLanguage}
                      >
                        <option value="">Select Language</option>
                        {this.state.filter_language.map((item, index) => {
                          return (
                            <option
                              value={item.value}
                              key={index}
                              selected={item.default ? true : false}
                              default={item.default}
                            >
                              {item.label}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>Boards</Form.Label>
                      <Form.Control
                        value={this.state.selected_board}
                        as="select"
                        name="board"
                        onChange={this.changeBoards}
                      >
                        <option value="">Select Boards</option>
                        {this.state.filter_boards.map((item, index) => {
                          return (
                            <option
                              value={item.value}
                              key={index}
                              selected={item.default ? true : false}
                              default={item.default}
                            >
                              {item.label}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                    {/* <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Form.Label>Search</Form.Label>
                                            <Form.Control type="input" placeholder="enter the search text" onChange={(event) => this.setState({ selected_search: event.target.value })} />
                                        </Form.Group> */}
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>Subject</Form.Label>
                      <Form.Control
                        value={this.state.selected_subject}
                        as="select"
                        name="subject"
                        disabled={this.state.subject_disabled}
                        onChange={this.changeSubject}
                      >
                        <option value="">Select Subject</option>
                        {!this.state.subject_disabled &&
                          this.state.filter_subject.map((item, index) => {
                            return (
                              <option
                                value={item.value}
                                key={index}
                                selected={item.default ? true : false}
                                default={item.default}
                              >
                                {item.label}
                              </option>
                            );
                          })}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>Class</Form.Label>
                      <Form.Control
                        value={this.state.selected_class_no}
                        as="select"
                        name="grade"
                        disabled={this.state.class_disabled}
                        onChange={this.changeClass}
                      >
                        <option value="">Select Class</option>
                        {!this.state.class_disabled &&
                          this.state.filter_class_no.map((item, index) => (
                            <option
                              value={item.grade}
                              key={index}
                              selected={
                                item?.grade ===
                                this?.state?.selected_class_no?.toString()
                              }
                            >
                              {item.grade}
                            </option>
                          ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>Chapter</Form.Label>
                      <Form.Control
                        value={this.state.chapter}
                        as="select"
                        name="chapter_no"
                        disabled={this.state.chapter_disabled}
                        onChange={this.changeChapter}
                      >
                        <option value="">Select Chapter</option>
                        {!this.state.chapter_disabled &&
                          this.state.filter_chapter.map((item, index) => (
                            <option
                              value={item.chapter_no}
                              key={index}
                              selected={
                                item?.chapter_no ===
                                this?.state?.selected_chapter?.toString()
                              }
                            >
                              {item.chapter_no} - {item.chapter_name_original}
                            </option>
                          ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="exampleForm.ControlSelect1">
                      <Form.Label>Topic</Form.Label>
                      <Form.Control
                        as="select"
                        value={this.state.topic}
                        name="video_topic_no"
                        disabled={this.state.topic_disabled}
                        onChange={this.changeTopic}
                      >
                        <option value="">Select Topic</option>
                        {!this.state.topic_disabled &&
                          this.state.filter_topic.map((item, index) => (
                            <option
                              value={item.video_topic_no}
                              key={index}
                              selected={
                                item?.video_topic_no ===
                                this?.state?.selected_topic?.toString()
                              }
                            >
                              {item.video_topic_no} - {item.video_topic_name}
                            </option>
                          ))}
                      </Form.Control>
                    </Form.Group>
                    <div className="submit-btn">
                      <Button
                        type="reset"
                        onClick={() => {
                          localStorage.clear();
                          window.location.reload(false);
                        }}
                        className="mb-2"
                      >
                        Reset
                      </Button>
                      <Button type="submit" id="submit" className="mb-2">
                        Search
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            <div className="col-md-8 col-sm-12 col-xs-12">
              <div className="mobile-search-bar school-logo-for-mobile">
                <div
                  className="filter-form sidebar-new-search-field"
                  ref="form1"
                  //onSubmit={this.handleSearchSubmit}
                >
                  <div
                    className={
                      this.state.mobileSearchButton
                        ? "active new-search-bar-for-mobile"
                        : "new-search-bar-for-mobile"
                    }
                  >
                    <div className="search-box">
                      <input
                        onChange={(e) =>
                          this.setState({ selected_search: e?.target.value })
                        }
                        placeholder="Search here"
                        className="search-input"
                      />
                      <button onClick={this.handleMobileSearchSubmit} btn="btn">
                        <img src={SearchIcon} alt="" />
                      </button>
                    </div>
                    <button
                      onClick={() =>
                        this.setState({
                          mobileSearchButton: !this.state.mobileSearchButton,
                        })
                      }
                      className="btn open-search"
                    >
                      <img className="search" src={SearchIcon} alt="" />
                      <img
                        className="close-icon"
                        src={CloseIconSearch}
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </div>
              <div
                className={
                  this.state.scrollValue > 250
                    ? "sidebar-main school-logo-for-mobile fixed-scroll"
                    : "sidebar-main school-logo-for-mobile"
                }
              >
                <div className="school-logo-box">
                  {/* <img src={SchoolLogo} alt="" /> */}
                  <Link to="./">
                    <img src={this.state.school.logo} alt="" />
                    <p>{this.state.school.name}</p>
                  </Link>
                </div>
              </div>

              <div className="desktop-search-bar hide-desktop">
                <Form
                  className="filter-form sidebar-new-search-field"
                  ref="form1"
                  onSubmit={this.handleSearchSubmit}
                >
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    {/* <Form.Label>Search</Form.Label> */}
                    <Form.Control
                      type="input"
                      placeholder="Enter the search text"
                      value={this.state.selected_search}
                      onChange={(event) =>
                        this.setState({
                          selected_search: event.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  <div className="submit-btn">
                    <Button
                      onClick={() =>
                        this.setState({
                          hasMoreVideos: true,
                          hasMorePractice: true,
                          hasMorePlaylist: true,
                        })
                      }
                      type="submit"
                      id="submit"
                      className="mb-2"
                    >
                      Search
                    </Button>
                  </div>
                </Form>
              </div>
              <div className="videos-tabs">
                <Tabs
                  defaultActiveKey={
                    localStorage.getItem("selectedTab")
                      ? localStorage.getItem("selectedTab").toString()
                      : "playlist"
                  }
                  onSelect={(k) => {
                    localStorage.setItem("selectedTab", k);
                  }}
                  transition={false}
                  id="noanim-tab-example"
                >
                  <Tab
                    eventKey="playlist"
                    title={
                      <React.Fragment>
                        Playlists
                        <Badge variant="light">
                          {this.state.playlists_count}
                        </Badge>
                      </React.Fragment>
                    }
                  >
                    <div
                      className="practice-questions-box"
                      id="scrollable-playlists"
                    >
                      {/* {!this.state.playlists.length && !this.state.isLoading ? (
                         <h3 className="question-error">
                          Loading...
                          </h3>
                      ) : this.state.isLoading ? (
                        <h3>Oops! <br /> The resource you are looking for is not
                          available. Please come back later.</h3>
                      ) : ( */}
                      {!this.state.playlists.length && !this.state.isLoading ? (
                        <h3 className="question-error">
                          Content for this language will come soon! Thank you
                          for your interest.
                        </h3>
                      ) : this.state.isLoading ? (
                        <h3>Loading...</h3>
                      ) : (
                        <>
                          <p className="share-link-text">Share Link</p>
                          {this.state.playlists.map((item, index) => (
                            <Card key={index}>
                              <Link
                                to={{
                                  pathname:
                                    this.props.match.url.replace(/\/$/, "") +
                                    "/pl/" +
                                    item.slug,
                                }}
                              >
                                <Card.Img variant="top" src={CardImg5} />
                              </Link>
                              <Card.Body>
                                <Link
                                  to={{
                                    pathname:
                                      this.props.match.url.replace(/\/$/, "") +
                                      "/pl/" +
                                      item.slug,
                                  }}
                                >
                                  <Card.Title>
                                    {/* <img src={Question} alt="question" />  */}
                                    <b>{item.video_topic_name}</b>
                                    <span>
                                      {" "}
                                      {item.chapter_name_original} | Class{" "}
                                      {item.grade}{" "}
                                    </span>
                                  </Card.Title>
                                </Link>
                                <CopyToClipboard
                                  text={
                                    window.location.origin.toString() +
                                    this.props.match.url.replace(/\/$/, "") +
                                    "/pl/" +
                                    item.slug
                                  }
                                  onCopy={() =>
                                    this.setState({
                                      copiedLink:
                                        window.location.origin.toString() +
                                        this.props.match.url.replace(
                                          /\/$/,
                                          ""
                                        ) +
                                        "/pl/" +
                                        item.slug,
                                      copiedSlug: item.slug,
                                      copiedType: "pl",
                                    })
                                  }
                                >
                                  <Button
                                    variant="primary"
                                    onClick={this.openModal}
                                  >
                                    <img src={Share} alt="" />
                                    <span>Share</span>
                                  </Button>
                                </CopyToClipboard>
                              </Card.Body>
                            </Card>
                          ))}
                          {/* </InfiniteScroll> */}
                          <center>
                            <button
                              style={{ textAlign: "center" }}
                              className="btn btn-success"
                              onClick={this.fetchMorePlaylistData}
                              disabled={!this.state.hasMorePlaylist}
                            >
                              {this.state.hasMorePlaylist
                                ? "Load more"
                                : "You have reached the end"}
                            </button>
                          </center>
                        </>
                      )}
                    </div>
                  </Tab>
                  <Tab
                    eventKey="practice-questions"
                    title={
                      <React.Fragment>
                        Practice Quizzes
                        <Badge variant="light">
                          {this.state.practiceQues_count}
                        </Badge>
                      </React.Fragment>
                    }
                  >
                    <div
                      className="practice-questions-box"
                      id="scrollable-practice"
                    >
                      {!this.state.practiceQues.length &&
                      !this.state.isLoading ? (
                        <h3 className="question-error">
                          Content for this language will come soon! Thank you
                          for your interest
                        </h3>
                      ) : this.state.isLoading ? (
                        <h3>Loading...</h3>
                      ) : (
                        <>
                          <p className="share-link-text">Share Link</p>
                          {/* <InfiniteScroll
                                                        dataLength={this.state.practiceQues.length} //This is important field to render the next data
                                                        next={this.fetchMorePracticeData}
                                                        hasMore={this.state.hasMorePractice}
                                                        loader={<h4>Loading...</h4>}
                                                        endMessage={
                                                            <p style={{ textAlign: 'center' }}>
                                                            <b>Yay! You have seen it all</b>
                                                            </p>
                                                        }
                                                        scrollableTarget="scrollable-practice"
                                                        > */}
                          {this.state.practiceQues.map((item, index) => (
                            <Card key={index}>
                              <Link
                                to={{
                                  pathname:
                                    this.props.match.url.replace(/\/$/, "") +
                                    "/p/" +
                                    item.question_setId,
                                }}
                              >
                                <Card.Img
                                  className="practice-ques-img"
                                  variant="top"
                                  src={CardImg1}
                                />
                              </Link>
                              <Card.Body>
                                <Link
                                  to={{
                                    pathname:
                                      this.props.match.url.replace(/\/$/, "") +
                                      "/p/" +
                                      item.question_setId,
                                  }}
                                >
                                  <Card.Title>
                                    {/* <img src={Question} alt="question" />  */}
                                    <b>{item.video_topic_name}</b>
                                    <span>
                                      {" "}
                                      {item.chapter_name_original} | Class{" "}
                                      {item.grade}{" "}
                                    </span>
                                  </Card.Title>
                                </Link>

                                <CopyToClipboard
                                  text={
                                    window.location.origin.toString() +
                                    this.props.match.url.replace(/\/$/, "") +
                                    "/p/" +
                                    item.question_setId
                                  }
                                  onCopy={() =>
                                    this.setState({
                                      copiedLink:
                                        window.location.origin.toString() +
                                        this.props.match.url.replace(
                                          /\/$/,
                                          ""
                                        ) +
                                        "/p/" +
                                        item.question_setId,
                                      copiedSlug: item.question_setId,
                                      copiedType: "p",
                                    })
                                  }
                                >
                                  <Button
                                    variant="primary"
                                    onClick={this.openModal}
                                  >
                                    <img src={Share} alt="" />
                                    <span>Share</span>
                                  </Button>
                                </CopyToClipboard>
                              </Card.Body>
                            </Card>
                          ))}
                          {/* </InfiniteScroll> */}
                          <center>
                            <button
                              style={{ textAlign: "center" }}
                              className="btn btn-success"
                              onClick={this.fetchMorePracticeData}
                              disabled={!this.state.hasMorePractice}
                            >
                              {this.state.hasMorePractice
                                ? "Load more"
                                : "You have reached the end"}
                            </button>
                          </center>
                        </>
                      )}
                    </div>
                  </Tab>
                  <Tab
                    eventKey="videos"
                    title={
                      <React.Fragment>
                        Videos
                        <Badge variant="light">{this.state.videos_count}</Badge>
                      </React.Fragment>
                    }
                  >
                    <div
                      className="practice-questions-box"
                      id="scrollable-videos"
                    >
                      {!this.state.videos.length && !this.state.isLoading ? (
                        <h3 className="question-error">
                          Content for this language will come soon! Thank you
                          for your interest
                        </h3>
                      ) : this.state.isLoading ? (
                        <h3>Loading...</h3>
                      ) : (
                        <>
                          <p className="share-link-text">Share Link</p>

                          {/* <InfiniteScroll
                                                        dataLength={this.state.videos.length} //This is important field to render the next data
                                                        next={this.fetchMoreVideoData}
                                                        hasMore={true}
                                                        loader={<h4>Loading...</h4>}
                                                        endMessage={
                                                            <p style={{ textAlign: 'center' }}>
                                                            <b>Yay! You have seen it all</b>
                                                            </p>
                                                        }
                                                        scrollableTarget="scrollable-videos"
                                                        
                                                        > */}
                          {this.state.videos.map((item, index) => (
                            <Card key={index}>
                              <Link
                                to={{
                                  pathname:
                                    this.props.match.url.replace(/\/$/, "") +
                                    "/v/" +
                                    item.slug,
                                }}
                              >
                                <Card.Img
                                  variant="top"
                                  src={ytbThumbnail(item.youtube_link, "mq")}
                                />
                              </Link>
                              <Card.Body>
                                <Link
                                  to={{
                                    pathname:
                                      this.props.match.url.replace(/\/$/, "") +
                                      "/v/" +
                                      item.slug,
                                  }}
                                >
                                  <Card.Title>
                                    {/* <img src={VideoIcon} alt="video" />  */}
                                    <b>{item.video_title}</b>{" "}
                                    <span>
                                      {" "}
                                      {item.chapter_name_original} | Class{" "}
                                      {item.grade}{" "}
                                    </span>{" "}
                                  </Card.Title>
                                </Link>
                                <CopyToClipboard
                                  text={
                                    window.location.origin.toString() +
                                    this.props.match.url.replace(/\/$/, "") +
                                    "/v/" +
                                    item.slug
                                  }
                                  onCopy={() => {
                                    this.setState({
                                      copiedLink:
                                        window.location.origin.toString() +
                                        this.props.match.url.replace(
                                          /\/$/,
                                          ""
                                        ) +
                                        "/v/" +
                                        item.slug,
                                      copiedSlug: item.slug,
                                      copiedType: "v",
                                    });
                                  }}
                                >
                                  <Button
                                    variant="primary"
                                    onClick={this.openModal}
                                  >
                                    <img src={Share} alt="" />
                                    <span>Share</span>
                                  </Button>
                                </CopyToClipboard>
                              </Card.Body>
                            </Card>
                          ))}

                          {/* </InfiniteScroll> */}
                          <center>
                            <button
                              style={{ textAlign: "center" }}
                              className="btn btn-success"
                              onClick={this.fetchMoreVideoData}
                              disabled={!this.state.hasMoreVideos}
                            >
                              {this.state.hasMoreVideos
                                ? "Load more"
                                : "You have reached the end"}
                            </button>
                          </center>
                        </>
                      )}
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        <Modal
          className="share-popup"
          show={this.state.isOpen}
          onHide={this.closeModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Share</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="share-icon">
              <ul>
                <li onClick={() => this.handleCopied("Whatsapp")}>
                  {" "}
                  <Link
                    to={{
                      pathname: "https://wa.me?text=" + this.state.copiedLink,
                    }}
                    target="_blank"
                  >
                    {" "}
                    <img src={PopupIcon1} alt="" /> <span>Whatsapp</span>
                  </Link>{" "}
                </li>
                <li onClick={() => this.handleCopied("Facebook")}>
                  {" "}
                  <Link
                    to={{
                      pathname:
                        "https://www.facebook.com/sharer/sharer.php?u=" +
                        this.state.copiedLink,
                    }}
                    target="_blank"
                  >
                    {" "}
                    <img src={PopupIcon2} alt="" /> <span>Facebook</span>{" "}
                  </Link>{" "}
                </li>
                <li onClick={() => this.handleCopied("Twitter")}>
                  {" "}
                  <Link
                    to={{
                      pathname:
                        "https://twitter.com/intent/tweet?url=" +
                        this.state.copiedLink,
                    }}
                    target="_blank"
                  >
                    {" "}
                    <img src={PopupIcon4} alt="" /> <span>Twitter</span>{" "}
                  </Link>{" "}
                </li>
              </ul>
            </div>
            <div className="copied-link">
              <Form.Group>
                <Form.Control
                  size="lg"
                  type="text"
                  placeholder="Large text"
                  value={this.state.copiedLink}
                />
                <Button
                  variant="secondary"
                  onClick={() => {
                    toast("Link copied to clipboard");
                    this.closeModal;
                    this.handleCopied("COPY");
                  }}
                >
                  COPY
                </Button>
              </Form.Group>
              {/* <Link to={{ pathname: this.state.copiedLink }} target="_blank" > {this.state.copiedLink} </Link> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default Home;
